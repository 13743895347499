import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import styles from './StreamBreakdownPage.module.css';
import { getCategoryColor } from './CategoryColors';
import { formatAirtime, formatWatchtime, formatNumberWithCommas } from '../../utils/format';

const StreamBreakdownPage = () => {
  const { username, streamId } = useParams();
  const [streamDetails, setStreamDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`https://backend.streamerstats.com/kick/slug/${username}/${streamId}`)
      .then((response) => response.json())
      .then((data) => setStreamDetails(data))
      .catch((error) => {
        console.error('Fetch error:', error);
        navigate(`/kick/${username}`);
      });
  }, [username, streamId, navigate]);

  if (!streamDetails) {
    return <div>Loading...</div>;
  }

  const getGraphData = (streamDetails) => {
    const filteredData = streamDetails.data;

    const titleChangeData = streamDetails.data.reduce((acc, item, index, array) => {
      if (index === 0) {
        acc.push({ x: new Date(item.scrape_time), y: 0, label: item.session_title, thumbnail: item.thumbnail });
      }
      if (index > 0 && item.session_title !== array[index - 1].session_title) {
        acc.push({ x: new Date(item.scrape_time), y: 0, label: item.session_title, thumbnail: item.thumbnail });
      }
      return acc;
    }, []);

    const categoryChangeData = streamDetails.data.reduce((acc, item, index, array) => {
      if (index > 0 && item.category_name !== array[index - 1].category_name) {
        acc.push({ x: new Date(item.scrape_time), y: item.viewers, index });
      }
      return acc;
    }, []);

    const viewers = filteredData.map(d => d.viewers);
    const peak = viewers.length > 0 ? Math.max(...viewers) : 0;

    const followerChanges = Array.isArray(streamDetails.followers) ? streamDetails.followers.reduce((acc, item, index, array) => {
      if (index > 0) {
        const change = item.count - array[index - 1].count;
        acc.push({
          x: new Date(item.scrape_time),
          y: Math.abs(change),
          actual: change,
          color: change >= 0 ? 'green' : 'red',
        });
      }
      return acc;
    }, []): [];

    const hostData = streamDetails.hosts && streamDetails.hosts.map(host => ({
      x: new Date(host.timestamp),
      y: 0,
      viewers: host.viewers,
      slug: host.hoster_slug,
    }));
    const peakIndex = viewers.indexOf(peak);
    const peakDataPoint = peakIndex !== -1 ? filteredData[peakIndex] : null;

    return {
      filteredData,
      titleChangeData,
      categoryChangeData,
      viewers,
      peak,
      followerChanges,
      chartData: {
        labels: filteredData.map(d => new Date(d.scrape_time)),
        datasets: [
          {
            type: 'line',
            label: 'Viewers',
            data: viewers,
            borderColor: 'rgba(75,192,192,1)',
            backgroundColor: (context) => {
              const chart = context.chart;
              const { ctx, chartArea } = chart;

              if (!chartArea) {
                return 'rgba(75,192,192,0.5)';
              }

              const { left, right } = chartArea;

              const gradient = ctx.createLinearGradient(left, 0, right, 0);
              let prevCategory = streamDetails.data[0].category_name;

              gradient.addColorStop(0, getCategoryColor(prevCategory));

              streamDetails.data.forEach((item, index) => {
                const offset = index / (streamDetails.data.length - 1);
                if (index > 0 && item.category_name !== prevCategory) {
                  gradient.addColorStop(offset, getCategoryColor(prevCategory));
                  gradient.addColorStop(offset, getCategoryColor(item.category_name));
                  prevCategory = item.category_name;
                }
              });

              gradient.addColorStop(1, getCategoryColor(prevCategory));
              return gradient;
            },
            tension: 0.1,
            fill: true,
            pointRadius: 4,
            pointBackgroundColor: 'rgba(75,192,192,1)',
            tooltip: {
              callbacks: {
                label: (context) => {
                  const index = context.dataIndex;
                  const category = streamDetails.data[index].category_name;
                  const viewers = context.raw;
                  return [`Category: ${category}`, `Viewers: ${viewers}`];
                },
              },
            }
          },
          {
            type: 'scatter',
            label: 'Hosts',
            data: hostData,
            borderColor: 'purple',
            backgroundColor: 'purple',
            pointRadius: 10,
            showLine: false,
            pointStyle: 'circle',
            tooltip: {
              callbacks: {
                label: (context) => {
                  const data = context.raw;
                  return [`Host: ${data.slug}`, `Viewers: ${data.viewers}`];
                },
              },
            },
          },
          {
            type: 'scatter',
            label: 'Title Change',
            data: titleChangeData,
            borderColor: 'red',
            backgroundColor: 'red',
            pointRadius: 12,
            showLine: false,
            pointStyle: 'circle',
            datalabels: {
              display: true,
              align: 'top',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              borderRadius: 4,
              color: 'white',
              font: {
                size: 10,
              },
              formatter: (value) => value.label,
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const data = context.raw;
                  return `Title: ${data.label}`;
                },
              },
            },
          },
          {
            type: 'line',
            label: 'Average',
            data: new Array(viewers.length).fill(streamDetails.average),
            borderColor: 'blue',
            borderDash: [10, 5],
            pointRadius: 0,
            datalabels: {
              display: false,
            },
            tooltip: {
              external: null,
            }
          },
          {
            type: 'scatter',
            label: 'Peak',
            data: [{ x: new Date(peakDataPoint.scrape_time), y: peak }],
            borderColor: 'green',
            backgroundColor: 'green',
            pointRadius: 12,
            hoverRadius: 15,
            datalabels: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  // const data = context.raw;
                  return `Peak Viewership`;
                },
              },
            },
          },
          {
            type: 'bar',
            label: 'Follower Change',
            data: followerChanges,
            backgroundColor: followerChanges.map((change) => change.color),
            borderColor: followerChanges.map((change) => change.color),
            borderWidth: 2,
            yAxisID: 'y-followers',
            tooltip: {
              enabled: false, // Disable tooltip for this dataset
            },
          },
        ]
      }
    };
  };

  const { filteredData, titleChangeData, categoryChangeData, viewers, peak, chartData } = getGraphData(streamDetails);

  const customTooltip = (context) => {
    let tooltipEl = document.getElementById('chartjs-tooltip');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.innerHTML = '<table></table>';
      document.body.appendChild(tooltipEl);
    }

    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    function getBody(bodyItem) {
      return bodyItem.lines;
    }
    let noImg = false;
    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      const bodyLines = tooltipModel.body.map(getBody);

      let innerHtml = '<thead>';

      titleLines.forEach(function (title) {
        innerHtml += '<tr><th>' + title + '</th></tr>';
      });

      innerHtml += '</thead><tbody>';

      bodyLines.forEach(function (body, i) {
        const colors = tooltipModel.labelColors[i];
        const style = 'background:' + colors.backgroundColor;
        const span = '<span style="' + style + '"></span>';
        if (body[0].includes('Average') || body[0].includes('Follower')) {
          innerHtml = "";
          noImg = true;
        }
        if (body[0].includes('Peak') || body[0].includes('Title') || body[0].includes('Follower') || body[0].includes('Average')) {
          innerHtml += '<tr><td>' + span + body[0] + '</td></tr>';
        } else {
          innerHtml += '<tr><td>' + span + body[0] + '</td></tr>';
          innerHtml += '<tr><td>' + span + body[1] + '</td></tr>';
        }
      });

      innerHtml += '</tbody>';

      const tableRoot = tooltipEl.querySelector('table');
      tableRoot.innerHTML = innerHtml;

      if (tooltipModel.dataPoints[0].datasetIndex === 4) {
        const item = context.tooltip.dataPoints[0];
        const data = item.raw;
        innerHtml = `
          <thead>
            <tr><th>Time: ${item.label}</th></tr>
          </thead>
          <tbody>
            <tr><td>Change: ${data.actual >= 0 ? '+' : '-'}${data.y}</td></tr>
          </tbody>
        `;
        tableRoot.innerHTML = innerHtml;
      } else {
        const item = streamDetails.data[tooltipModel.dataPoints[0].dataIndex];
        if (item && !noImg) {
          const img = document.createElement('img');
          img.src = item.thumbnail;
          img.style.width = '100px';
          tableRoot.appendChild(img);
        }
      }
    }

    const position = context.chart.canvas.getBoundingClientRect();

    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    tooltipEl.style.font = tooltipModel.options.bodyFont.string;
    tooltipEl.style.padding = tooltipModel.options.padding + 'px ' + tooltipModel.options.padding + 'px';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.backgroundColor = 'rgba(0,0,0,0.8)';
    tooltipEl.style.borderRadius = '8px';
    tooltipEl.style.boxShadow = '0px 0px 10px rgba(0,0,0,0.25)';
    tooltipEl.style.color = 'white';
  };

  const options = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'minute',
          stepSize: 5,
        },
        ticks: {
          stepSize: 5,
          callback: (value) => {
            const date = new Date(value);
            let hours = date.getHours();
            const minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;
            const minutesStr = minutes < 10 ? '0' + minutes : minutes;
            return `${hours}:${minutesStr} ${ampm}`;
          },
          autoSkip: true,
          maxTicksLimit: 6,
        },
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Viewers',
        },
      },
      'y-followers': {
        type: 'linear',
        position: 'right', // Position the axis on the right
        grid: {
          drawOnChartArea: false, // Only draw the grid on the main y-axis
        },
        title: {
          display: true,
          text: 'Follower Changes',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: customTooltip,
        intersect: false,
        mode: 'nearest',
        position: 'nearest',
        distance: 20,
        
      },
      annotation: {
        annotations: {
          ...(streamDetails.average ? {
            averageLine: {
              type: 'line',
              scaleID: 'y',
              value: streamDetails.average,
              borderColor: 'blue',
              borderDash: [10, 5],
              borderWidth: 2,
              label: {
                content: `Average`,
                enabled: true,
                position: 'start',
                backgroundColor: 'rgba(0,0,0,0)',
                color: 'blue',
                xAdjust: -50,
                font: {
                  size: 10,
                  style: 'bold'
                }
              },
              tooltip: {
                external: null,
              }
            }
          } : {}),
          ...(peak ? {
            peakPoint: {
              type: 'point',
              xValue: new Date(filteredData[viewers.indexOf(peak)].scrape_time),
              yValue: peak,
              backgroundColor: 'green',
              borderColor: 'green',
              radius: 12,
              label: {
                content: `${peak}`,
                enabled: true,
                position: 'top',
                backgroundColor: 'rgba(75,192,192,0.8)',
                font: {
                  size: 14,
                  style: 'bold'
                }
              },
              tooltip: {
                enabled: false
              }
            }
          } : {}),
          ...(categoryChangeData.length > 0 ? categoryChangeData.map((data, index) => ({
            type: 'line',
            scaleID: 'x',
            value: data.x,
            borderColor: 'red',
            borderDash: [4, 4],
            borderWidth: 1,
            label: {
              content: 'Category change',
              enabled: true,
              position: 'start',
              backgroundColor: 'rgba(0,0,0,0)',
              color: 'red',
              yAdjust: -20,
              font: {
                size: 10,
                style: 'bold'
              }
            }
          })) : {}),
          ...(titleChangeData.length > 0 ? titleChangeData.map((data, index) => ({
            type: 'point',
            xValue: data.x,
            yValue: data.y,
            backgroundColor: 'red',
            borderColor: 'red',
            radius: 12,
            label: {
              content: data.label,
              enabled: true,
              position: 'top',
              backgroundColor: 'rgba(0,0,0,0.7)',
              color: 'white',
              yAdjust: -20,
              font: {
                size: 10,
                style: 'bold'
              }
            }
          })) : {})
        }
      }
    }
  };

  const renderExtraDetails = () => {
    const categoryStats = streamDetails.data.reduce((acc, item, index, array) => {
      if (!acc[item.category_name]) {
        acc[item.category_name] = {
          viewers: [],
          peak: 0,
          watchtime: 0,
          airtime: 0,
          lastScrapeTime: new Date(item.scrape_time),
        };
      }
      if (!acc[item.category_name].lastScrapeTime) {
        acc[item.category_name].lastScrapeTime = new Date(item.scrape_time);
      }
      
      
  
      const currentScrapeTime = new Date(item.scrape_time);
      const previousScrapeTime = acc[item.category_name].lastScrapeTime;
      const airtimeDifference = (currentScrapeTime - previousScrapeTime) / 1000; // difference in seconds
      
      acc[item.category_name].viewers.push(item.viewers);
      acc[item.category_name].peak = Math.max(acc[item.category_name].peak, item.viewers);
      acc[item.category_name].watchtime += item.viewers * airtimeDifference / 60 / 60; // in hours
      acc[item.category_name].airtime += airtimeDifference / 60; // in minutes

      if (index < array.length - 1 && array[index + 1].category_name !== item.category_name) {
        acc[item.category_name].lastScrapeTime = null;
      } else {
        acc[item.category_name].lastScrapeTime = currentScrapeTime;
      }
  
      return acc;
    }, {});

    return Object.entries(categoryStats).map(([category, stats], index) => {
      const average = stats.viewers.reduce((a, b) => a + b, 0) / stats.viewers.length;
      const categoryColor = getCategoryColor(category);

      return (
        <div key={index} className={styles.card} style={{ backgroundColor: categoryColor }}>
          <p className={styles.cardTitle}>{category}</p>
          <p className={styles.cardValue}>Peak: {formatNumberWithCommas(stats.peak)}</p>
          <p className={styles.cardValue}>Average: {formatNumberWithCommas(average.toFixed(0))}</p>
          <p className={styles.cardValue}>Watchtime: {formatWatchtime(Math.floor(stats.watchtime ))}</p>
          <p className={styles.cardValue}>Airtime: {formatAirtime((stats.airtime))}</p>
        </div>
      );
    });
  };

  const renderTopChattersTable = () => {
    if (!streamDetails || !streamDetails.top_chatters) {
      return null;
    }
    const topChatters = Object.entries(streamDetails.top_chatters)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 10);

    return (
      <>
        <h2 className={styles.breakdownSubHeader}>Top Chatters</h2>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Username</th>
              <th>Messages</th>
            </tr>
          </thead>
          <tbody>
            {topChatters.map(([username, messages], index) => (
              <tr key={username}>
                <td>{index + 1}</td>
                <td>{username}</td>
                <td>{messages}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  const renderTopSubGiftersTable = () => {
    if (!streamDetails || !streamDetails.top_sub_gifters) {
      return null;
    }

    const topSubGifters = Object.entries(streamDetails.top_sub_gifters)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 10);

    return (
      <>
        <h2 className={styles.breakdownSubHeader}>Top Sub Gifters</h2>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Username</th>
              <th>Gifted Subs</th>
            </tr>
          </thead>
          <tbody>
            {topSubGifters.map(([username, subs], index) => (
              <tr key={username}>
                <td>{index + 1}</td>
                <td>{username}</td>
                <td>{subs}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  const followerChange = streamDetails.followers && streamDetails.followers.length > 1
  ? streamDetails.followers[streamDetails.followers.length - 1].count - streamDetails.followers[0].count
  : 0;
  const followerChangeSign = followerChange > 0 ? '+' : '';

  return (
    <div className={styles.breakdownContainer}>
      <h1 className={styles.breakdownHeader}>Stream Breakdown</h1>
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <p className={styles.cardTitle}>Average</p>
          <p className={styles.cardValue}>{streamDetails && formatNumberWithCommas(streamDetails.average)}</p>
        </div>
        <div className={styles.card}>
          <p className={styles.cardTitle}>Peak</p>
          <p className={styles.cardValue}>{streamDetails && formatNumberWithCommas(streamDetails.peak)}</p>
        </div>
        <div className={styles.card}>
          <p className={styles.cardTitle}>Watchtime</p>
          <p className={styles.cardValue}>{streamDetails && formatWatchtime(streamDetails.watchtime)}</p>
        </div>
        <div className={styles.card}>
          <p className={styles.cardTitle}>Airtime</p>
          <p className={styles.cardValue}>{streamDetails && formatAirtime((streamDetails.airtime/60))}</p>
        </div>
        <div className={styles.card}>
          <p className={styles.cardTitle}>Subs</p>
          <p className={styles.cardValue}>{streamDetails && formatNumberWithCommas(streamDetails.subscriptions)}</p>
        </div>
        <div className={styles.card}>
          <p className={styles.cardTitle}>Gifted Subs</p>
          <p className={styles.cardValue}>{streamDetails && formatNumberWithCommas(streamDetails.gifted_subscriptions)}</p>
        </div>
        <div className={styles.card}>
          <p className={styles.cardTitle}>Follower Change</p>
          <p className={styles.cardValue}>{streamDetails && `${followerChangeSign}${formatNumberWithCommas(followerChange)}`}</p>
        </div>
        <div className={styles.card}>
          <p className={styles.cardTitle}>Blank</p>
          <p className={styles.cardValue}></p>
        </div>
        <div className={styles.card}>
          <p className={styles.cardTitle}>Messages</p>
          <p className={styles.cardValue}>{streamDetails && streamDetails.messages_per_minute.toFixed(2)} / minute</p>
        </div>
        <div className={styles.card}>
          <p className={styles.cardTitle}>Unique Chatter Messages</p>
          <p className={styles.cardValue}>{streamDetails && streamDetails.unique_messages_per_minute.toFixed(2)} / minute</p>
        </div>
        <div className={styles.card}>
          <p className={styles.cardTitle}>Unique Chatters</p>
          <p className={styles.cardValue}>{streamDetails && formatNumberWithCommas(streamDetails.unique_chatters)}</p>
        </div>
        <div className={styles.card}>
          <p className={styles.cardTitle}>Unique Chatters Last Five Minutes</p>
          <p className={styles.cardValue}>{streamDetails && formatNumberWithCommas(streamDetails.unique_chatters_last_5)}</p>
        </div>
      </div>
      <Line data={chartData} options={options} />
      <h2 className={styles.breakdownSubHeader}>Category Details</h2>
      <div className={styles.cardContainer}>{renderExtraDetails()}</div>
      {renderTopChattersTable()}
      {renderTopSubGiftersTable()}
    </div>
  );
};

export default StreamBreakdownPage;
