// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Browse from './pages/BrowsePage/BrowsePage';
import SearchPage from './pages/SearchPage/SearchPage';
import StreamBreakdownPage from './pages/StreamBreakdownPage/StreamBreakdownPage';
import StreamerStatsPage from './pages/StreamerStatsPage/StreamerStatsPage';
import ChatLogs from './pages/ChatLogs/ChatLogs';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<SearchPage />} />
          <Route path="/browse" element={<Browse />} />
          <Route path="/kick/:username" element={<StreamerStatsPage />} />
          <Route path="/kick/:username/:streamId" element={<StreamBreakdownPage />} />
          <Route path="/chatlogs/:username" element={<ChatLogs />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;