import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SearchPage.module.css';

const SearchPage = () => {
  const [username, setUsername] = useState('');
  const [chatLogUsername, setChatLogUsername] = useState('');
  const [totalViewers, setTotalViewers] = useState(0);
  const [liveChannels, setLiveChannels] = useState(0);
  const [topChannels, setTopChannels] = useState([]);
  const [topCategories, setTopCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://backend.streamerstats.com/stats')
      .then((response) => response.json())
      .then((data) => {
        setTotalViewers(data.totalViewers);
        setLiveChannels(data.liveChannels);
        setTopChannels(data.topChannels || []);
        setTopCategories(data.topCategories || []);
      });
  }, []);

  const createSlug = (str) => {
    return str.toLowerCase().replace(/\s+/g, '').replace(/_/g, '-');
  };  

  const handleSearch = () => {
    const slug = createSlug(username);
    navigate(`/kick/${slug}`);
  };

  const handleChatLogSearch = () => {
    const slug = createSlug(chatLogUsername);
    navigate(`/chatlogs/${slug}`);
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleChatLogSearchPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className={styles.searchContainer}>
      <div className={styles.infographicContainer}>
        <div className={styles.infographic}>
          <h2>Total Viewers</h2>
          <p className={styles.number}>{totalViewers.toLocaleString()}</p>
        </div>
        <div className={styles.infographic}>
          <h2>Live Channels</h2>
          <p className={styles.number}>{liveChannels.toLocaleString()}</p>
        </div>
      </div>
      <h1>Search for a Streamer</h1>
      <div className={styles.searchSection}>
        <input
          type="text"
          autoComplete="off"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Enter username"
          onKeyDown={handleSearchKeyPress}
          className={styles.searchInput}
        />
        <button onClick={handleSearch} className={styles.searchButton}>
          Search
        </button>
      </div>
      <h1>Search for User Chat Logs</h1>
      <div className={styles.searchSection}>
        <input
          type="text"
          autoComplete="off"
          value={chatLogUsername}
          onChange={(e) => setChatLogUsername(e.target.value)}
          onKeyDown={handleChatLogSearchPress}
          placeholder="Enter username for chat logs"
          className={styles.searchInput}
        />
        <button onClick={handleChatLogSearch} className={styles.searchButton}>
          Search
        </button>
      </div>
      <h2>Top 10 Live Channels</h2>
      <table className={styles.topTable}>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Channel</th>
            <th>Viewers</th>
          </tr>
        </thead>
        <tbody>
          {topChannels.map((channel, index) => (
            <tr key={channel.LivestreamID}>
              <td>{index + 1}</td>
              <td>
                <a href={`/kick/${createSlug(channel.username)}`} className={styles.channelLink}>
                  <img src={channel.profile_pic} alt={`${channel.username}'s profile`} className={styles.profilePic} />
                  {channel.username}
                </a>
              </td>
              <td className={styles.number}>{channel.viewers}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <h2>Top 10 Categories</h2>
      <table className={styles.topTable}>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Category</th>
            <th>Viewers</th>
          </tr>
        </thead>
        <tbody>
          {topCategories.map((category, index) => (
            <tr key={category.CategoryID}>
              <td>{index + 1}</td>
              <td>{category.category_name}</td>
              <td className={styles.number}>{category.total_viewers}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SearchPage;
