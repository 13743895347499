// src/utils/format.js
export const formatAirtime = (minutes) => {
  const roundedMinutes = Math.round(minutes);
  const hours = Math.floor(roundedMinutes / 60);
  const remainingMinutes = roundedMinutes % 60;
  return `${hours} hours ${remainingMinutes} minutes`;
};

export const formatWatchtime = (hours) => {
  const roundedHours = Math.round(hours);
  return `${roundedHours} hours`;
};

export const formatNumberWithCommas = (number) => {
  const roundedNumber = Math.round(number);
  return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
