import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import Modal from 'react-modal';
import styles from './StreamerStatsPage.module.css';
import { formatNumberWithCommas } from '../../utils/format';

const StreamerStatsPage = () => {
  const { username } = useParams();
  const [streamerData, setStreamerData] = useState(null);
  const [similarAudienceData, setSimilarAudienceData] = useState([]);
  const [selectedStream, setSelectedStream] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timescale, setTimescale] = useState('last_24_hours');
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`https://backend.streamerstats.com/kick/slug/${username}`)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error('User not found');
          } else {
            throw new Error('Failed to fetch data');
          }
        }
        return response.json();
      })
      .then((data) => setStreamerData(data))
      .catch((error) => setError(error.message));
  }, [username]);

  useEffect(() => {
    fetch(`https://backend.streamerstats.com/kick/audience2/${username}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch similar audience data');
        }
        return response.json();
      })
      .then((data) => setSimilarAudienceData(data))
      .catch((error) => setError(error.message));
  }, [username]);

  const openModal = (stream) => {
    setSelectedStream(stream);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedStream(null);
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!streamerData) {
    return <div>Loading...</div>;
  }

  const {
    profilepic,
    bio,
    slug,
    streams,
    averages,
    total_followers,
    discord,
    facebook,
    instagram,
    tiktok,
    twitter,
    youtube,
    unique_chatters,
    peaks,
    airtime,
    watchtime,
    subs_last_30_days,
    gifted_subs_last_30_days,
  } = streamerData;

  const getMiniGraphData = (stream) => {
    const miniData = stream.data.filter((d, index) => {
      const date = new Date(d.scrape_time);
      return date.getMinutes() % 5 === 0 && date.getSeconds() === 0;
    });

    return {
      labels: miniData.map((d) => new Date(d.scrape_time)),
      datasets: [
        {
          data: miniData.map((d) => d.viewers),
          fill: false,
          borderColor: 'rgba(75,192,192,1)',
          tension: 0.1,
          pointRadius: 0,
        },
      ],
    };
  };

  const getGraphData = (stream) => {
    const filteredData = stream.data.filter((d) => {
      const date = new Date(d.scrape_time);
      return date.getMinutes() % 5 === 0 && date.getSeconds() === 0;
    });

    return {
      labels: filteredData.map((d) => new Date(d.scrape_time)),
      datasets: [
        {
          data: filteredData.map((d) => d.viewers),
          fill: true,
          borderColor: 'rgba(75,192,192,1)',
          tension: 0.1,
        },
      ],
    };
  };

  const miniGraphOptions = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'minute',
        },
        display: false,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    maintainAspectRatio: false,
  };

  const graphOptions = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'minute',
          stepSize: 5,
        },
        ticks: {
          stepSize: 5,
          callback: (value) => {
            const date = new Date(value);
            let hours = date.getHours();
            const minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;
            const minutesStr = minutes < 10 ? '0' + minutes : minutes;
            return `${hours}:${minutesStr} ${ampm}`;
          },
          autoSkip: true,
          maxTicksLimit: 6,
        },
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Viewers',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return new Intl.NumberFormat().format(context.raw);
          },
          title: (tooltipItems) => {
            return tooltipItems[0].label.toLocaleString();
          },
        },
      },
    },
  };

  const renderGraphs = () => {
    const graphs = [];

    for (let i = 0; i < 10; i++) {
      if (streams[i]) {
        const graphData = getMiniGraphData(streams[i]);

        graphs.push(
          <div key={i} className={styles.miniGraph} onClick={() => openModal(streams[i])}>
            <Line data={graphData} options={miniGraphOptions} style={{ width: '100%', height: '100%' }} />
          </div>
        );
      } else {
        graphs.push(<div key={i} className={styles.miniGraphEmpty}></div>);
      }
    }

    return graphs;
  };

  return (
    <div className={styles.statsContainer}>
      <div className={styles.profileSection}>
        <div className={styles.profilePicContainer}>
          <div className={styles.username}>{username}</div>
          <img src={profilepic} alt={`${username}'s profile`} className={styles.profilePic} />
        </div>
        <div className={styles.bioContainer}>
          <p className={styles.bio}>{bio}</p>
          {slug && (
            <a href={`https://kick.com/${slug}`} target="_blank" rel="noopener noreferrer" className={styles.kickLink}>
              <svg
                className="h-auto w-[80px]"
                width="80"
                height="26"
                viewBox="0 0 80 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 0H8.57143V5.71429H11.4286V2.85714H14.2857V0H22.8571V8.57143H20V11.4286H17.1429V14.2857H20V17.1429H22.8571V25.7143H14.2857V22.8571H11.4286V20H8.57143V25.7143H0V0ZM57.1429 0H65.7143V5.71429H68.5714V2.85714H71.4286V0H80V8.57143H77.1429V11.4286H74.2857V14.2857H77.1429V17.1429H80V25.7143H71.4286V22.8571H68.5714V20H65.7143V25.7143H57.1429V0ZM25.7143 0H34.2857V25.7143H25.7143V0ZM45.7143 0H40V2.85714H37.1429V22.8571H40V25.7143H45.7143H54.2857V17.1429H45.7143V8.57143H54.2857V0H45.7143Z"
                  fill="white"
                ></path>
              </svg>
            </a>
          )}
        </div>
      </div>

      {(discord || facebook || instagram || tiktok || twitter || youtube) && (
        <div className={styles.socialLinks}>
          {discord && (
            <a href={`https://discord.gg/${discord}`} target="_blank" rel="noopener noreferrer">
              Discord
            </a>
          )}
          {facebook && (
            <a href={`https://facebook.com/${facebook}`} target="_blank" rel="noopener noreferrer">
              Facebook
            </a>
          )}
          {instagram && (
            <a href={`https://instagram.com/${instagram}`} target="_blank" rel="noopener noreferrer">
              Instagram
            </a>
          )}
          {tiktok && (
            <a href={`https://tiktok.com/@${tiktok}`} target="_blank" rel="noopener noreferrer">
              TikTok
            </a>
          )}
          {twitter && (
            <a href={`https://twitter.com/${twitter}`} target="_blank" rel="noopener noreferrer">
              Twitter
            </a>
          )}
          {youtube && (
            <a href={`https://youtube.com/${youtube}`} target="_blank" rel="noopener noreferrer">
              YouTube
            </a>
          )}
        </div>
      )}
      <h1 className={styles.statsHeader}>Statistics</h1>
      <div className={styles.miniCards}>
        <div className={styles.miniCard}>
          <h3>Average Viewers</h3>
          <select value={timescale} onChange={(e) => setTimescale(e.target.value)} className={styles.timescaleSelector}>
            <option value="last_24_hours">24 hours</option>
            <option value="last_7_days">7 days</option>
            <option value="last_30_days">30 days</option>
          </select>
          <p>{formatNumberWithCommas(averages?.[timescale] || 0)}</p>
        </div>
        <div className={styles.miniCard}>
          <h3>Peak Viewers</h3>
          <select value={timescale} onChange={(e) => setTimescale(e.target.value)} className={styles.timescaleSelector}>
            <option value="last_24_hours">24 hours</option>
            <option value="last_7_days">7 days</option>
            <option value="last_30_days">30 days</option>
          </select>
          <p>{formatNumberWithCommas(peaks?.[timescale] || 0)}</p>
        </div>
        <div className={styles.miniCard}>
          <h3>Total Hours Watched</h3>
          <p>{(watchtime / 60).toFixed(0) || 0}</p>
        </div>
        <div className={styles.miniCard}>
          <h3>Total Airtime</h3>
          <p>{(airtime / 60).toFixed(0) || 0}</p>
        </div>
        <div className={styles.miniCard}>
          <h3>Total Followers</h3>
          <p>{formatNumberWithCommas(total_followers || 0)}</p>
        </div>
        <div className={styles.miniCard}>
          <h3>Unique Chatters</h3>
          <p>{formatNumberWithCommas(unique_chatters || 0)}</p>
        </div>
        <div className={styles.miniCard}>
          <h3>Subs Last 30 Day</h3>
          <p>{formatNumberWithCommas(subs_last_30_days || 0)}</p>
        </div>
        <div className={styles.miniCard}>
          <h3>Gifted Subs Last 30 Day</h3>
          <p>{formatNumberWithCommas(gifted_subs_last_30_days || 0)}</p>
        </div>
      </div>
      <h2>Last 10 Streams</h2>
      <div className={styles.graphGrid}>{renderGraphs()}</div>
      <h2>Streams</h2>
      <ul className={styles.streamList}>
        {streams.map((stream) => (
          <li key={stream.livestream_id} className={styles.streamListItem}>
            <Link to={`/kick/${username}/${stream.livestream_id}`} className={styles.streamLink}>
              <img
                src={stream.data[stream.data.length - 1].thumbnail}
                alt={`${stream.data[stream.data.length - 1].session_title} thumbnail`}
                className={styles.streamThumbnail}
              />
              <div className={styles.streamInfo}>
                <p>Title: {stream.data[stream.data.length - 1].session_title}</p>
                <p>Start Time: {new Date(stream.start_time).toLocaleString()}</p>
                <p>Average Viewers: {formatNumberWithCommas(stream.average)}</p>
              </div>
            </Link>
          </li>
        ))}
      </ul>
      {similarAudienceData && 
        <>
          <h2>Similar Audiences</h2>
          <table className={styles.audienceTable}>
            <thead>
              <tr>
                <th>Username</th>
                <th>Similar Chatters</th>
                <th>Total Messages</th>
              </tr>
            </thead>
            <tbody>
              {similarAudienceData.map((audience, index) => (
                <tr key={index}>
                  <td>{audience.username}</td>
                  <td>{formatNumberWithCommas(audience.unique_senders)}</td>
                  <td>{formatNumberWithCommas(audience.total_messages)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      }
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} className={styles.modal} overlayClassName={styles.overlay}>
        {selectedStream && (
          <div className={styles.modalContent}>
            <h2>{selectedStream.data[selectedStream.data.length - 1].session_title}</h2>
            <Line data={getGraphData(selectedStream)} options={graphOptions} />
            <Link to={`/kick/${username}/${selectedStream.livestream_id}`} style={{textDecoration: 'none'}}className={styles.closeButton}>Go to Stream</Link>
            <button onClick={closeModal} className={styles.closeButton}>
              Close
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default StreamerStatsPage;
