import React from 'react';

const categories = {
  "Apex Legends": 'rgba(255, 87, 34, 0.5)',
  "Call of Duty: Modern Warfare II": 'rgba(121, 85, 72, 0.5)',
  "Fortnite": 'rgba(33, 150, 243, 0.5)',
  "Call of Duty: Warzone 2.0": 'rgba(244, 67, 54, 0.5)',
  "League of Legends": 'rgba(76, 175, 80, 0.5)',
  "Overwatch 2": 'rgba(255, 193, 7, 0.5)',
  "Among Us": 'rgba(156, 39, 176, 0.5)',
  "Grand Theft Auto V (GTA)": 'rgba(0, 188, 212, 0.5)',
  "FIFA 23": 'rgba(139, 195, 74, 0.5)',
  "Minecraft": 'rgba(205, 220, 57, 0.5)',
  "Counter-Strike: Global Offensive": 'rgba(255, 235, 59, 0.5)',
  "Rust": 'rgba(255, 152, 0, 0.5)',
  "Dota 2": 'rgba(142, 26, 18, 0.5)',
  "Just Chatting": 'rgba(255, 99, 132, 0.5)',
  "Pools, Hot Tubs & Bikinis": 'rgba(255, 64, 129, 0.5)',
  "ASMR": 'rgba(123, 31, 162, 0.5)',
  "Podcasts": 'rgba(48, 63, 159, 0.5)',
  "Fitness and Health": 'rgba(3, 169, 244, 0.5)',
  "Food & Drink": 'rgba(0, 188, 212, 0.5)',
  "Live DJ": 'rgba(0, 150, 136, 0.5)',
  "Guitar": 'rgba(76, 175, 80, 0.5)',
  "Slots & Casino": 'rgba(205, 220, 57, 0.5)',
  "Poker": 'rgba(255, 235, 59, 0.5)',
  "Art": 'rgba(255, 193, 7, 0.5)',
  "Software Development": 'rgba(255, 87, 34, 0.5)',
  "Video Production": 'rgba(121, 85, 72, 0.5)',
  "Fall Guys": 'rgba(33, 150, 243, 0.5)',
  "Final Fantasy XIV": 'rgba(244, 67, 54, 0.5)',
  "PUBG: Battlegrounds": 'rgba(76, 175, 80, 0.5)',
  "Raft": 'rgba(255, 193, 7, 0.5)',
  "Warframe": 'rgba(33, 33, 33, 0.5)',
  "New World": 'rgba(156, 39, 176, 0.5)',
  "Resident Evil": 'rgba(0, 188, 212, 0.5)',
  "Retro Games": 'rgba(139, 195, 74, 0.5)',
  "Teamfight Tactics": 'rgba(205, 220, 57, 0.5)',
  "VALORANT": 'rgba(255, 152, 0, 0.5)',
  "VRChat": 'rgba(255, 64, 129, 0.5)',
  "World of Warcraft": 'rgba(123, 31, 162, 0.5)',
  "Animals, Aquariums & Zoos": 'rgba(48, 63, 159, 0.5)',
  "Chess": 'rgba(3, 169, 244, 0.5)',
  "Science & Technology": 'rgba(0, 188, 212, 0.5)',
  "News": 'rgba(0, 150, 136, 0.5)',
  "Sports": 'rgba(76, 175, 80, 0.5)',
  "Runescape": 'rgba(205, 220, 57, 0.5)',
  "Rocket League": 'rgba(255, 235, 59, 0.5)',
  "Tom Clancy's Rainbow Six Siege": 'rgba(255, 193, 7, 0.5)',
  "Super Mario 64": 'rgba(255, 87, 34, 0.5)',
  "Diablo II": 'rgba(121, 85, 72, 0.5)',
  "Diablo III": 'rgba(33, 150, 243, 0.5)',
  "Escape From Tarkov": 'rgba(244, 67, 54, 0.5)',
  "Hearthstone": 'rgba(76, 175, 80, 0.5)',
  "Path of Exile": 'rgba(255, 193, 7, 0.5)',
  "Black Desert Online": 'rgba(33, 33, 33, 0.5)',
  "Lost Ark": 'rgba(156, 39, 176, 0.5)',
  "Ark: Survival Evolved": 'rgba(0, 188, 212, 0.5)',
  "Dead By Daylight": 'rgba(139, 195, 74, 0.5)',
  "Pokémon Scarlet/Violet": 'rgba(205, 220, 57, 0.5)',
  "Roblox": 'rgba(255, 152, 0, 0.5)',
  "iRacing": 'rgba(255, 64, 129, 0.5)',
  "Cuphead": 'rgba(123, 31, 162, 0.5)',
  "Clone Hero": 'rgba(48, 63, 159, 0.5)',
  "Super Smash Bros. Ultimate": 'rgba(3, 169, 244, 0.5)',
  "Destiny 2": 'rgba(0, 188, 212, 0.5)',
  "The Dark Pictures Anthology: The Devil in Me": 'rgba(76, 175, 80, 0.5)',
  "Magic: The Gathering": 'rgba(205, 220, 57, 0.5)',
  "Tibia": 'rgba(255, 235, 59, 0.5)',
  "Red Dead Redemption II": 'rgba(255, 193, 7, 0.5)',
  "Death Stranding": 'rgba(255, 87, 34, 0.5)',
  "Old School RuneScape": 'rgba(121, 85, 72, 0.5)',
  "Halo Infinite": 'rgba(33, 150, 243, 0.5)',
  "Genshin Impact": 'rgba(244, 67, 54, 0.5)',
  "Dark Souls III": 'rgba(76, 175, 80, 0.5)',
  "Sea of Thieves": 'rgba(255, 193, 7, 0.5)',
  "Phasmophobia": 'rgba(33, 33, 33, 0.5)',
  "SMITE": 'rgba(156, 39, 176, 0.5)',
  "The Sims 4": 'rgba(0, 188, 212, 0.5)',
  "The Last of Us Part II": 'rgba(139, 195, 74, 0.5)',
  "Resident Evil 2": 'rgba(205, 220, 57, 0.5)',
  "Undertale": 'rgba(255, 152, 0, 0.5)',
  "Fallout 4": 'rgba(255, 64, 129, 0.5)',
  "Dark Souls II": 'rgba(123, 31, 162, 0.5)',
  "The Last of Us": 'rgba(48, 63, 159, 0.5)',
  "Mario Kart 8 Deluxe": 'rgba(3, 169, 244, 0.5)',
  "Dark Souls": 'rgba(0, 188, 212, 0.5)',
  "Resident Evil 4": 'rgba(76, 175, 80, 0.5)',
  "Marvel's Spider-Man: Miles Morales": 'rgba(205, 220, 57, 0.5)',
  "TETR.IO": 'rgba(255, 235, 59, 0.5)',
  "Super Mario Bros. 3": 'rgba(255, 193, 7, 0.5)',
  "Marvel's Spider-Man": 'rgba(255, 87, 34, 0.5)',
  "Red Dead Redemption": 'rgba(121, 85, 72, 0.5)',
  "Need for Speed Unbound": 'rgba(33, 150, 243, 0.5)',
  "Valheim": 'rgba(244, 67, 54, 0.5)',
  "Albion Online": 'rgba(76, 175, 80, 0.5)',
  "Elden Ring": 'rgba(255, 193, 7, 0.5)',
  "DayZ": 'rgba(33, 33, 33, 0.5)',
  "SCUM": 'rgba(156, 39, 176, 0.5)',
  "Clash Royale": 'rgba(0, 188, 212, 0.5)',
  "Garena Free Fire": 'rgba(139, 195, 74, 0.5)',
  "MapleStory": 'rgba(205, 220, 57, 0.5)',
  "7 Days to Die": 'rgba(255, 152, 0, 0.5)',
  "Jackbox Party Packs": 'rgba(255, 64, 129, 0.5)',
  "osu!": 'rgba(123, 31, 162, 0.5)',
  "Resident Evil Village": 'rgba(48, 63, 159, 0.5)',
  "The Elder Scrolls Online": 'rgba(3, 169, 244, 0.5)',
  "Microsoft Flight Simulator": 'rgba(0, 188, 212, 0.5)',
  "The Elder Scrolls V: Skyrim": 'rgba(76, 175, 80, 0.5)',
  "Resident Evil 7: Biohazard": 'rgba(205, 220, 57, 0.5)',
  "Teenage Mutant Ninja Turtles: Shredder's Revenge": 'rgba(255, 235, 59, 0.5)',
  "Silent Hill 2": 'rgba(255, 193, 7, 0.5)',
  "Untitled Goose Game": 'rgba(255, 87, 34, 0.5)',
  "Star Wars Battlefront II": 'rgba(121, 85, 72, 0.5)',
  "Silent Hill": 'rgba(33, 150, 243, 0.5)',
  "Star Wars: The Old Republic": 'rgba(244, 67, 54, 0.5)',
  "Demon's Souls": 'rgba(76, 175, 80, 0.5)',
  "Resident Evil 3": 'rgba(255, 193, 7, 0.5)',
  "Metal Gear Rising: Revengeance": 'rgba(33, 33, 33, 0.5)',
  "World of Tanks": 'rgba(156, 39, 176, 0.5)',
  "Project Zomboid": 'rgba(0, 188, 212, 0.5)',
  "No More Heroes": 'rgba(139, 195, 74, 0.5)',
  "The Forest": 'rgba(205, 220, 57, 0.5)',
  "Left 4 Dead 2": 'rgba(255, 152, 0, 0.5)',
  "Mount & Blade II: Bannerlord": 'rgba(255, 64, 129, 0.5)',
  "Knight Online": 'rgba(123, 31, 162, 0.5)',
  "World of Warships": 'rgba(48, 63, 159, 0.5)',
  "RimWorld": 'rgba(3, 169, 244, 0.5)',
  "Grand Theft Auto: San Andreas": 'rgba(0, 188, 212, 0.5)',
  "Lineage 2 Mobile": 'rgba(76, 175, 80, 0.5)',
  "Metroid Prime": 'rgba(205, 220, 57, 0.5)',
  "Hollow Knight": 'rgba(255, 235, 59, 0.5)',
  "Forza Horizon 5": 'rgba(255, 193, 7, 0.5)',
  "Splatoon 3": 'rgba(255, 87, 34, 0.5)',
  "Pokémon GO": 'rgba(121, 85, 72, 0.5)',
  "Halo: The Master Chief Collection": 'rgba(33, 150, 243, 0.5)',
  "No Man's Sky": 'rgba(244, 67, 54, 0.5)',
  "Conan Exiles": 'rgba(76, 175, 80, 0.5)',
  "Grounded": 'rgba(255, 193, 7, 0.5)',
  "Hell Let Loose": 'rgba(33, 33, 33, 0.5)',
  "Stray": 'rgba(156, 39, 176, 0.5)',
  "Days Gone": 'rgba(0, 188, 212, 0.5)',
  "Grand Theft Auto: Vice City": 'rgba(139, 195, 74, 0.5)',
  "South Park: The Stick of Truth": 'rgba(205, 220, 57, 0.5)',
  "EverQuest": 'rgba(255, 152, 0, 0.5)',
  "Poppy Playtime": 'rgba(255, 64, 129, 0.5)',
  "Friday the 13th: The Game": 'rgba(123, 31, 162, 0.5)',
  "Brawlhalla": 'rgba(48, 63, 159, 0.5)',
  "Celeste": 'rgba(3, 169, 244, 0.5)',
  "Terraria": 'rgba(0, 188, 212, 0.5)',
  "Games + Demos": 'rgba(76, 175, 80, 0.5)',
  "Crosswords & Word Games": 'rgba(205, 220, 57, 0.5)',
  "Music Stations": 'rgba(255, 235, 59, 0.5)',
  "Other, Watch Party": 'rgba(255, 193, 7, 0.5)',
  "Devour": 'rgba(255, 87, 34, 0.5)',
  "Special Events": 'rgba(121, 85, 72, 0.5)',
  "Diablo IV": 'rgba(33, 150, 243, 0.5)',
  "Call of Duty: Modern Warfare 2": 'rgba(244, 67, 54, 0.5)',
  "Call Of Duty: Modern Warfare": 'rgba(76, 175, 80, 0.5)',
  "Battlefield 2042": 'rgba(255, 193, 7, 0.5)',
  "Tom Clancy's The Division 2": 'rgba(33, 33, 33, 0.5)',
  "For Honor": 'rgba(156, 39, 176, 0.5)',
  "Euro Truck Simulator 2": 'rgba(0, 188, 212, 0.5)',
  "The Legend of Zelda: Ocarina of Time": 'rgba(139, 195, 74, 0.5)',
  "Farming Simulator 22": 'rgba(205, 220, 57, 0.5)',
  "Alien: Isolation": 'rgba(255, 152, 0, 0.5)',
  "Call of Duty: Black Ops III": 'rgba(255, 64, 129, 0.5)',
  "American Truck Simulator": 'rgba(123, 31, 162, 0.5)',
  "Subnautica": 'rgba(48, 63, 159, 0.5)',
  "SnowRunner": 'rgba(3, 169, 244, 0.5)',
  "Call of Duty: Black Ops": 'rgba(0, 188, 212, 0.5)',
  "Call of Duty: Black Ops II": 'rgba(76, 175, 80, 0.5)',
  "The Legend of Zelda": 'rgba(205, 220, 57, 0.5)',
  "The Legend of Zelda: Twilight Princess": 'rgba(255, 235, 59, 0.5)',
  "The Legend of Zelda: The Wind Waker": 'rgba(255, 193, 7, 0.5)',
  "Ultimate Marvel vs. Capcom 3": 'rgba(255, 87, 34, 0.5)',
  "Metal Gear Solid": 'rgba(121, 85, 72, 0.5)',
  "War Thunder": 'rgba(33, 150, 243, 0.5)',
  "Disney Dreamlight Valley": 'rgba(244, 67, 54, 0.5)',
  "Squad": 'rgba(76, 175, 80, 0.5)',
  "The Long Drive": 'rgba(255, 193, 7, 0.5)',
  "Gotham Knights": 'rgba(33, 33, 33, 0.5)',
  "Politics": 'rgba(156, 39, 176, 0.5)',
  "Borderlands 3": 'rgba(0, 188, 212, 0.5)',
  "Borderlands": 'rgba(139, 195, 74, 0.5)',
  "Borderlands 2": 'rgba(205, 220, 57, 0.5)',
  "Star Citizen": 'rgba(255, 152, 0, 0.5)',
  "Tower of Fantasy": 'rgba(255, 64, 129, 0.5)',
  "Crypto & Trading": 'rgba(123, 31, 162, 0.5)',
  "PlanetSide 2": 'rgba(48, 63, 159, 0.5)',
  "Undecember": 'rgba(3, 169, 244, 0.5)',
  "Ranch Simulator": 'rgba(0, 188, 212, 0.5)',
  "Super Smash Bros. Melee": 'rgba(76, 175, 80, 0.5)',
  "Vampire Survivors": 'rgba(205, 220, 57, 0.5)',
  "Cyberpunk 2077": 'rgba(255, 235, 59, 0.5)',
  "Wakfu": 'rgba(255, 193, 7, 0.5)',
  "PGA Tour 2K23": 'rgba(255, 87, 34, 0.5)',
  "Realm Royale Reforged": 'rgba(121, 85, 72, 0.5)',
  "Garry's Mod": 'rgba(33, 150, 243, 0.5)',
  "Pokémon Unite": 'rgba(244, 67, 54, 0.5)',
  "Hearts of Iron IV": 'rgba(76, 175, 80, 0.5)',
  "Planet Crafter": 'rgba(255, 193, 7, 0.5)',
  "Insurgency: Sandstorm": 'rgba(33, 33, 33, 0.5)',
  "Tower Unite": 'rgba(156, 39, 176, 0.5)',
  "Dead Space": 'rgba(0, 188, 212, 0.5)',
  "Batman: Arkham Asylum": 'rgba(139, 195, 74, 0.5)',
  "Tabletop RPGs": 'rgba(205, 220, 57, 0.5)',
  "Stalcraft": 'rgba(255, 152, 0, 0.5)',
  "Stumble Guys": 'rgba(255, 64, 129, 0.5)',
  "God of War": 'rgba(123, 31, 162, 0.5)',
  "Divinity: Original Sin II": 'rgba(48, 63, 159, 0.5)',
  "Dishonored": 'rgba(3, 169, 244, 0.5)',
  "Dishonored 2": 'rgba(0, 188, 212, 0.5)',
  "Assetto Corsa": 'rgba(76, 175, 80, 0.5)',
  "Assetto Corsa Competizione": 'rgba(205, 220, 57, 0.5)',
  "Horizon Zero Dawn": 'rgba(255, 235, 59, 0.5)',
  "Portal": 'rgba(255, 193, 7, 0.5)',
  "Portal 2": 'rgba(255, 87, 34, 0.5)',
  "Rise of the Tomb Raider": 'rgba(121, 85, 72, 0.5)',
  "Tomb Raider": 'rgba(33, 150, 243, 0.5)',
  "Shadow of the Tomb Raider": 'rgba(244, 67, 54, 0.5)',
  "Mass Effect Legendary Edition": 'rgba(76, 175, 80, 0.5)',
  "Brawl Stars": 'rgba(255, 193, 7, 0.5)',
  "Assassin's Creed Valhalla": 'rgba(33, 33, 33, 0.5)',
  "Far Cry 6": 'rgba(156, 39, 176, 0.5)',
  "BeamNG.drive": 'rgba(0, 188, 212, 0.5)',
  "VTubers": 'rgba(139, 195, 74, 0.5)',
  "World War 3": 'rgba(205, 220, 57, 0.5)',
  "Fallout 76": 'rgba(255, 152, 0, 0.5)',
  "The Witcher 3: Wild Hunt": 'rgba(255, 64, 129, 0.5)',
  "Warhammer: Vermintide 2": 'rgba(123, 31, 162, 0.5)',
  "Guilty Gear: Strive": 'rgba(48, 63, 159, 0.5)',
  "Sekiro: Shadows Die Twice": 'rgba(3, 169, 244, 0.5)',
  "Hunt: Showdown": 'rgba(0, 188, 212, 0.5)',
  "Makers & Crafting": 'rgba(76, 175, 80, 0.5)',
  "The Walking Dead: The Telltale Definitive Series": 'rgba(205, 220, 57, 0.5)',
  "Ready or Not": 'rgba(255, 235, 59, 0.5)',
  "Illuvium": 'rgba(255, 193, 7, 0.5)',
  "Dying Light": 'rgba(255, 87, 34, 0.5)',
  "The Simpsons": 'rgba(121, 85, 72, 0.5)',
  "Music Production": 'rgba(33, 150, 243, 0.5)',
  "Paladins": 'rgba(244, 67, 54, 0.5)',
  "Dark and Darker": 'rgba(76, 175, 80, 0.5)',
  "Polygon": 'rgba(255, 193, 7, 0.5)',
  "Pokémon Legends: Arceus": 'rgba(33, 33, 33, 0.5)',
  "Devil May Cry 4": 'rgba(156, 39, 176, 0.5)',
  "Devil May Cry 5": 'rgba(0, 188, 212, 0.5)',
  "Gears of War 3": 'rgba(139, 195, 74, 0.5)',
  "Gears of War 4": 'rgba(205, 220, 57, 0.5)',
  "Gran Turismo 7": 'rgba(255, 235, 59, 0.5)',
  "Stardew Valley": 'rgba(255, 193, 7, 0.5)',
  "Gears 5": 'rgba(255, 87, 34, 0.5)',
  "Pokémon Crystal": 'rgba(121, 85, 72, 0.5)',
  "Call of Duty: Black Ops Cold War": 'rgba(33, 150, 243, 0.5)',
  "Need for Speed Heat": 'rgba(244, 67, 54, 0.5)',
  "BattleBit": 'rgba(76, 175, 80, 0.5)',
  "Call of Duty: World at War": 'rgba(255, 193, 7, 0.5)',
  "Monster Hunter Rise": 'rgba(33, 33, 33, 0.5)',
  "Chivalry 2": 'rgba(156, 39, 176, 0.5)',
  "Pokémon FireRed / LeafGreen": 'rgba(0, 188, 212, 0.5)',
  "Police Simulator: Patrol Officers": 'rgba(139, 195, 74, 0.5)',
  "Sports Betting": 'rgba(205, 220, 57, 0.5)',
  "Lost Light": 'rgba(255, 152, 0, 0.5)',
  "Assassin's Creed Odyssey": 'rgba(255, 64, 129, 0.5)',
  "Age of Empires II: Definitive Edition": 'rgba(123, 31, 162, 0.5)',
  "NARAKA: BLADEPOINT": 'rgba(48, 63, 159, 0.5)',
  "Call of Duty: Mobile": 'rgba(3, 169, 244, 0.5)',
  "Dragon Ball FighterZ": 'rgba(0, 188, 212, 0.5)',
  "Dark Orbit": 'rgba(76, 175, 80, 0.5)',
  "MX Bikes": 'rgba(205, 220, 57, 0.5)',
  "Green Hell": 'rgba(255, 235, 59, 0.5)',
  "Battlefield 4": 'rgba(255, 193, 7, 0.5)',
  "Just Sleeping": 'rgba(255, 87, 34, 0.5)',
  "Mortal Online 2": 'rgba(121, 85, 72, 0.5)',
  "Call of Duty Zombies": 'rgba(33, 150, 243, 0.5)',
  "It Takes Two": 'rgba(244, 67, 54, 0.5)',
  "Ghostwire: Tokyo": 'rgba(76, 175, 80, 0.5)',
  "Tiny Tina's Wonderlands": 'rgba(255, 193, 7, 0.5)',
  "Horizon Forbidden West": 'rgba(33, 33, 33, 0.5)',
  "A Plague Tale: Requiem": 'rgba(156, 39, 176, 0.5)',
  "The Quarry": 'rgba(0, 188, 212, 0.5)',
  "Total War: Warhammer III": 'rgba(139, 195, 74, 0.5)',
  "Assassin's Creed": 'rgba(205, 220, 57, 0.5)',
  "Assassin's Creed Brotherhood": 'rgba(255, 152, 0, 0.5)',
  "Assassin's Creed IV Black Flag": 'rgba(255, 64, 129, 0.5)',
  "Total War: Rome II": 'rgba(123, 31, 162, 0.5)',
  "Age of Empires IV": 'rgba(48, 63, 159, 0.5)',
  "Auto Chess": 'rgba(3, 169, 244, 0.5)',
  "TheHunter: Call of the Wild": 'rgba(0, 188, 212, 0.5)',
  "Predecessor": 'rgba(76, 175, 80, 0.5)',
  "Bully: Scholarship Edition": 'rgba(205, 220, 57, 0.5)',
  "SCP: Labrat": 'rgba(255, 235, 59, 0.5)',
  "Deep Rock Galactic": 'rgba(255, 193, 7, 0.5)',
  "Reading": 'rgba(255, 87, 34, 0.5)',
  "V Rising": 'rgba(121, 85, 72, 0.5)',
  "Crossout": 'rgba(33, 150, 243, 0.5)',
  "The Simpsons: Hit & Run": 'rgba(244, 67, 54, 0.5)',
  "Pummel Party": 'rgba(76, 175, 80, 0.5)',
  "Rogue Company": 'rgba(255, 193, 7, 0.5)',
  "Heroes of the Storm": 'rgba(33, 33, 33, 0.5)',
  "Stranded Deep": 'rgba(156, 39, 176, 0.5)',
  "Beat Saber": 'rgba(0, 188, 212, 0.5)',
  "Farlight 84": 'rgba(139, 195, 74, 0.5)',
  "Super Mario Odyssey": 'rgba(205, 220, 57, 0.5)',
  "Super Mario 3D World": 'rgba(255, 152, 0, 0.5)',
  "Mario Party Superstars": 'rgba(255, 64, 129, 0.5)',
  "Clash of Clans": 'rgba(123, 31, 162, 0.5)',
  "Golf With Your Friends": 'rgba(48, 63, 159, 0.5)',
  "Astroneer": 'rgba(3, 169, 244, 0.5)',
  "Cult of the Lamb": 'rgba(0, 188, 212, 0.5)',
  "Don't Starve Together": 'rgba(76, 175, 80, 0.5)',
  "Biomutant": 'rgba(205, 220, 57, 0.5)',
  "Ghost of Tsushima": 'rgba(255, 235, 59, 0.5)',
  "Yu-Gi-Oh! Master Duel": 'rgba(255, 193, 7, 0.5)',
  "Final Fantasy XI": 'rgba(255, 87, 34, 0.5)',
  "Max Payne 2: The Fall of Max Payne": 'rgba(121, 85, 72, 0.5)',
  "Marvel's Avengers": 'rgba(33, 150, 243, 0.5)',
  "Age of Mythology": 'rgba(244, 67, 54, 0.5)',
  "Last Epoch": 'rgba(76, 175, 80, 0.5)',
  "Batman: Arkham City": 'rgba(255, 193, 7, 0.5)',
  "Tom Clancy's Ghost Recon Wildlands": 'rgba(33, 33, 33, 0.5)',
  "Mobile Legends: Bang Bang": 'rgba(156, 39, 176, 0.5)',
  "Hogwarts Legacy": 'rgba(0, 188, 212, 0.5)',
  "Slither.io": 'rgba(139, 195, 74, 0.5)',
  "Team Fortress 2": 'rgba(205, 220, 57, 0.5)',
  "Path of Titans": 'rgba(255, 152, 0, 0.5)',
  "Need For Speed Underground 2": 'rgba(255, 64, 129, 0.5)',
  "Final Fantasy VII": 'rgba(123, 31, 162, 0.5)',
  "HITMAN": 'rgba(48, 63, 159, 0.5)',
  "Monster Hunter: World": 'rgba(3, 169, 244, 0.5)',
  "Solasta: Crown of the Magister - Lost Valley": 'rgba(0, 188, 212, 0.5)',
  "Call of Duty: Black Ops 4": 'rgba(76, 175, 80, 0.5)',
  "Battlefield 1": 'rgba(205, 220, 57, 0.5)',
  "Battlefield V": 'rgba(255, 235, 59, 0.5)',
  "Call of Duty: Warzone": 'rgba(255, 193, 7, 0.5)',
  "Outlast II": 'rgba(255, 87, 34, 0.5)',
  "A Way Out": 'rgba(121, 85, 72, 0.5)',
  "Fishing Planet": 'rgba(33, 150, 243, 0.5)',
  "Quake Champions": 'rgba(244, 67, 54, 0.5)',
  "Fallout: New Vegas": 'rgba(76, 175, 80, 0.5)',
  "Forspoken": 'rgba(255, 193, 7, 0.5)',
  "Red Dead RP": 'rgba(33, 33, 33, 0.5)',
  "DCS World": 'rgba(156, 39, 176, 0.5)',
  "Naruto to Boruto: Shinobi Striker": 'rgba(0, 188, 212, 0.5)',
  "Core Keeper": 'rgba(139, 195, 74, 0.5)',
  "Kenshi": 'rgba(205, 220, 57, 0.5)',
  "Tales of Arise": 'rgba(255, 152, 0, 0.5)',
  "The Crew 2": 'rgba(255, 64, 129, 0.5)',
  "The Classrooms": 'rgba(123, 31, 162, 0.5)',
  "Human: Fall Flat": 'rgba(48, 63, 159, 0.5)',
  "Undisputed": 'rgba(3, 169, 244, 0.5)',
  "The Isle": 'rgba(0, 188, 212, 0.5)',
  "Uno": 'rgba(76, 175, 80, 0.5)',
  "Visage": 'rgba(205, 220, 57, 0.5)',
  "Dead Space (2023)": 'rgba(255, 235, 59, 0.5)',
  "Kingdom Hearts": 'rgba(255, 193, 7, 0.5)',
  "Rising Storm 2: Vietnam": 'rgba(255, 87, 34, 0.5)',
  "Evil West": 'rgba(121, 85, 72, 0.5)',
  "Deadside": 'rgba(33, 150, 243, 0.5)',
  "MultiVersus": 'rgba(244, 67, 54, 0.5)',
  "Layers of Fear 2": 'rgba(76, 175, 80, 0.5)',
  "Dying Light 2: Stay Human": 'rgba(255, 193, 7, 0.5)',
  "A Little to the Left": 'rgba(33, 33, 33, 0.5)',
  "CABAL Online": 'rgba(156, 39, 176, 0.5)',
  "Sons of the Forest": 'rgba(0, 188, 212, 0.5)',
  "God of War III": 'rgba(139, 195, 74, 0.5)',
  "San Andreas Multiplayer": 'rgba(205, 220, 57, 0.5)',
  "Mario & Luigi: Superstar Saga": 'rgba(255, 152, 0, 0.5)',
  "Tetris": 'rgba(255, 64, 129, 0.5)',
  "PUBG Mobile": 'rgba(123, 31, 162, 0.5)',
  "Weed Shop 3": 'rgba(48, 63, 159, 0.5)',
  "Left 4 Dead": 'rgba(3, 169, 244, 0.5)',
  "Honkai Impact 3rd": 'rgba(0, 188, 212, 0.5)',
  "House Flipper": 'rgba(76, 175, 80, 0.5)',
  "XDEFIANT": 'rgba(205, 220, 57, 0.5)',
  "Second Life": 'rgba(255, 235, 59, 0.5)',
  "Yakuza Kiwami": 'rgba(255, 193, 7, 0.5)',
  "BattleBlock Theater": 'rgba(255, 87, 34, 0.5)',
  "Castle Crashers": 'rgba(121, 85, 72, 0.5)',
  "Gang Beasts": 'rgba(33, 150, 243, 0.5)',
  "Aim Lab": 'rgba(244, 67, 54, 0.5)',
  "Zero Hour": 'rgba(76, 175, 80, 0.5)',
  "CarX Street": 'rgba(255, 193, 7, 0.5)',
  "Rec Room": 'rgba(33, 33, 33, 0.5)',
  "Body Art": 'rgba(156, 39, 176, 0.5)',
  "Company of Heroes 3": 'rgba(0, 188, 212, 0.5)',
  "Kingdom Hearts II": 'rgba(139, 195, 74, 0.5)',
  "NieR RepliCant": 'rgba(205, 220, 57, 0.5)',
  "NieR: Automata": 'rgba(255, 235, 59, 0.5)',
  "Space Engineers": 'rgba(255, 193, 7, 0.5)',
  "Plants vs. Zombies": 'rgba(255, 87, 34, 0.5)',
  "Mortal Kombat X": 'rgba(121, 85, 72, 0.5)',
  "Arena Breakout": 'rgba(33, 150, 243, 0.5)',
  "Octopath Traveler II": 'rgba(244, 67, 54, 0.5)',
  "Ace Combat 7": 'rgba(76, 175, 80, 0.5)',
  "Prodeus": 'rgba(255, 193, 7, 0.5)',
  "Geometry Dash": 'rgba(33, 33, 33, 0.5)',
  "Madison": 'rgba(156, 39, 176, 0.5)',
  "Metro: Last Light": 'rgba(0, 188, 212, 0.5)',
  "Metro Exodus": 'rgba(139, 195, 74, 0.5)',
  "Sackboy: A Big Adventure": 'rgba(205, 220, 57, 0.5)',
  "Car Mechanic Simulator 2021": 'rgba(255, 235, 59, 0.5)',
  "Motorsports": 'rgba(255, 193, 7, 0.5)',
  "LEGO Star Wars: The Skywalker Saga": 'rgba(255, 87, 34, 0.5)',
  "Tom Clancy's Ghost Recon: Breakpoint": 'rgba(121, 85, 72, 0.5)',
  "XCOM 2": 'rgba(33, 150, 243, 0.5)',
  "Overcooked! 2": 'rgba(244, 67, 54, 0.5)',
  "Middle-earth: Shadow of War": 'rgba(76, 175, 80, 0.5)',
  "3D Printing": 'rgba(255, 193, 7, 0.5)',
  "Vigor": 'rgba(33, 33, 33, 0.5)',
  "The Finals": 'rgba(156, 39, 176, 0.5)',
  "Enlisted": 'rgba(0, 188, 212, 0.5)',
  "Arma 3": 'rgba(139, 195, 74, 0.5)',
  "Contraband Police": 'rgba(205, 220, 57, 0.5)',
  "Foxhole": 'rgba(255, 235, 59, 0.5)',
  "Dragon Ball Legends": 'rgba(255, 193, 7, 0.5)',
  "Far Cry 5": 'rgba(255, 87, 34, 0.5)',
  "Noita": 'rgba(121, 85, 72, 0.5)',
  "MU Online": 'rgba(33, 150, 243, 0.5)',
  "Baldur's Gate 3": 'rgba(244, 67, 54, 0.5)',
  "Wormate.io": 'rgba(76, 175, 80, 0.5)',
  "Pokémon MMO 3D": 'rgba(255, 193, 7, 0.5)',
  "Dragon Ball Z: Kakarot": 'rgba(33, 33, 33, 0.5)',
  "L.A. Noire": 'rgba(156, 39, 176, 0.5)',
  "Exoprimal": 'rgba(0, 188, 212, 0.5)',
  "Dragon Ball Z: Dokkan Battle": 'rgba(139, 195, 74, 0.5)',
  "Kingdom Hearts III": 'rgba(205, 220, 57, 0.5)',
  "Way of the Hunter": 'rgba(255, 235, 59, 0.5)',
  "Resident Evil 2(1998)": 'rgba(255, 193, 7, 0.5)',
  "CarX Drift Racing Online": 'rgba(255, 87, 34, 0.5)',
  "Demonologist": 'rgba(121, 85, 72, 0.5)',
  "Little Nightmares": 'rgba(33, 150, 243, 0.5)',
  "Anno 1800": 'rgba(244, 67, 54, 0.5)',
  "Jak and Daxter": 'rgba(76, 175, 80, 0.5)',
  "Backrooms": 'rgba(255, 193, 7, 0.5)',
  "Isonzo": 'rgba(33, 33, 33, 0.5)',
  "FOREWARNED": 'rgba(156, 39, 176, 0.5)',
  "Killing Floor 2": 'rgba(0, 188, 212, 0.5)',
  "League of Legends: Wild Rift": 'rgba(139, 195, 74, 0.5)',
  "Detroit: Become Human": 'rgba(205, 220, 57, 0.5)',
  "Firewatch": 'rgba(255, 235, 59, 0.5)',
  "Session: Skate Sim": 'rgba(255, 193, 7, 0.5)',
  "Jurassic World Evolution 2": 'rgba(255, 87, 34, 0.5)',
  "Have a Nice Death": 'rgba(121, 85, 72, 0.5)',
  "Planet Zoo": 'rgba(33, 150, 243, 0.5)',
  "Honor of Kings": 'rgba(244, 67, 54, 0.5)',
  "Far Cry 4": 'rgba(76, 175, 80, 0.5)',
  "Population: One": 'rgba(255, 193, 7, 0.5)',
  "Fallout 3": 'rgba(33, 33, 33, 0.5)',
  "NCAA Football 14": 'rgba(156, 39, 176, 0.5)',
  "Pokémon Red": 'rgba(0, 188, 212, 0.5)',
  "Batman: Arkham Knight": 'rgba(139, 195, 74, 0.5)',
  "Bramble: The Mountain King": 'rgba(205, 220, 57, 0.5)',
  "Kingdom Come: Deliverance": 'rgba(255, 235, 59, 0.5)',
  "Pokémon Emerald": 'rgba(255, 193, 7, 0.5)',
  "Enter the Gungeon": 'rgba(255, 87, 34, 0.5)',
  "Star Wars Galaxies: An Empire Divided": 'rgba(121, 85, 72, 0.5)',
  "NASCAR Heat 5": 'rgba(33, 150, 243, 0.5)',
  "Toontown Online": 'rgba(244, 67, 54, 0.5)',
  "Ground Branch": 'rgba(76, 175, 80, 0.5)',
  "Paper Mario: The Thousand-Year Door": 'rgba(255, 193, 7, 0.5)',
  "Counter-Strike 2": 'rgba(33, 33, 33, 0.5)',
  "Dredge": 'rgba(156, 39, 176, 0.5)',
  "Pokémon HeartGold": 'rgba(0, 188, 212, 0.5)',
  "Pokémon Black": 'rgba(139, 195, 74, 0.5)',
  "Pokémon Sword": 'rgba(205, 220, 57, 0.5)',
  "Pokémon Shield": 'rgba(255, 235, 59, 0.5)',
  "Pokémon Shining Pearl": 'rgba(255, 193, 7, 0.5)',
  "Pokémon Violet": 'rgba(255, 87, 34, 0.5)',
  "Turbo Golf Racing": 'rgba(121, 85, 72, 0.5)',
  "The Dark Pictures Anthology: Little Hope": 'rgba(33, 150, 243, 0.5)',
  "Escape the Backrooms": 'rgba(244, 67, 54, 0.5)',
  "Crusader Kings III": 'rgba(76, 175, 80, 0.5)',
  "Digital Art": 'rgba(255, 193, 7, 0.5)',
  "Beyond: Two Souls": 'rgba(33, 33, 33, 0.5)',
  "Dungeons & Dragons": 'rgba(156, 39, 176, 0.5)',
  "Elite: Dangerous - Odyssey": 'rgba(0, 188, 212, 0.5)',
  "Mir 4": 'rgba(139, 195, 74, 0.5)',
  "EA Sports PGA Tour": 'rgba(205, 220, 57, 0.5)',
  "The Hunter Classic": 'rgba(255, 235, 59, 0.5)',
  "Kick TV Channels": 'rgba(255, 193, 7, 0.5)',
  "Dark Age of Camelot": 'rgba(255, 87, 34, 0.5)',
  "Fable Anniversary": 'rgba(121, 85, 72, 0.5)',
  "Fable II": 'rgba(33, 150, 243, 0.5)',
  "Roblox Studio": 'rgba(244, 67, 54, 0.5)',
  "PokeMMO": 'rgba(76, 175, 80, 0.5)',
  "SCP: Containment Breach - Unity Edition": 'rgba(205, 220, 57, 0.5)',
  "City of Heroes": 'rgba(255, 235, 59, 0.5)',
  "The Wild Eight": 'rgba(255, 193, 7, 0.5)',
  "Guild Wars": 'rgba(33, 33, 33, 0.5)',
  "Indie Games": 'rgba(156, 39, 176, 0.5)',
  "Call of Duty: Modern Warfare 3 (2011)": 'rgba(0, 188, 212, 0.5)',
  "Botany": 'rgba(139, 195, 74, 0.5)',
  "Pacify": 'rgba(205, 220, 57, 0.5)',
  "Duck Game": 'rgba(255, 152, 0, 0.5)',
  "Ghostrunner": 'rgba(255, 64, 129, 0.5)',
  "We Were Here": 'rgba(123, 31, 162, 0.5)',
  "Aion Classic": 'rgba(48, 63, 159, 0.5)',
  "Disco Elysium": 'rgba(3, 169, 244, 0.5)',
  "Phantasy Star Online Episode I & II": 'rgba(0, 188, 212, 0.5)',
  "Oxygen Not Included": 'rgba(76, 175, 80, 0.5)',
  "Thief Simulator": 'rgba(205, 220, 57, 0.5)',
  "Mad Max": 'rgba(255, 235, 59, 0.5)',
  "Star Wars Jedi: Survivor": 'rgba(255, 193, 7, 0.5)',
  "Deceit 2": 'rgba(33, 33, 33, 0.5)',
  "S.T.A.L.K.E.R. G.A.M.M.A.": 'rgba(156, 39, 176, 0.5)',
  "Slay the Spire": 'rgba(0, 188, 212, 0.5)',
  "The Ascent": 'rgba(139, 195, 74, 0.5)',
  "Outlast": 'rgba(205, 220, 57, 0.5)',
  "Earth Defense Force 6": 'rgba(255, 152, 0, 0.5)',
  "Gartic Phone": 'rgba(255, 64, 129, 0.5)',
  "Kukoro: Stream Chat Games": 'rgba(123, 31, 162, 0.5)',
  "Loop Hero": 'rgba(48, 63, 159, 0.5)',
  "Bleak Faith: Forsaken": 'rgba(3, 169, 244, 0.5)',
  "Mega Man X": 'rgba(0, 188, 212, 0.5)',
  "Professor Layton and the Diabolical Box": 'rgba(76, 175, 80, 0.5)',
  "Streets of Rage 4": 'rgba(205, 220, 57, 0.5)',
  "Automobilista 2": 'rgba(255, 235, 59, 0.5)',
  "Summer of '58": 'rgba(255, 193, 7, 0.5)',
  "Dungeon Fighter Online": 'rgba(255, 87, 34, 0.5)',
  "Uncharted 4: A Thief's End": 'rgba(121, 85, 72, 0.5)',
  "Call of Duty: DMZ": 'rgba(33, 150, 243, 0.5)',
  "Metroid Prime Remastered": 'rgba(244, 67, 54, 0.5)',
  "Dungeon Defenders II": 'rgba(76, 175, 80, 0.5)',
  "Sports Breaks": 'rgba(255, 193, 7, 0.5)',
  "Mega Man Battle Network Legacy Collection": 'rgba(33, 33, 33, 0.5)',
  "Manhunt": 'rgba(156, 39, 176, 0.5)',
  "Mechabellum": 'rgba(0, 188, 212, 0.5)',
  "Minecraft: Legends": 'rgba(139, 195, 74, 0.5)',
  "LEGO Batman 2: DC Super Heroes": 'rgba(205, 220, 57, 0.5)',
  "Wuthering Waves": 'rgba(255, 152, 0, 0.5)',
  "Zombies Ate My Neighbors": 'rgba(255, 64, 129, 0.5)',
  "Call of the Wild: The Angler": 'rgba(123, 31, 162, 0.5)',
  "Silkroad Online": 'rgba(48, 63, 159, 0.5)',
  "XCOM: Enemy Unknown": 'rgba(3, 169, 244, 0.5)',
  "Tom Clancy's Splinter Cell: Chaos Theory": 'rgba(0, 188, 212, 0.5)',
  "Tom Clancy's Splinter Cell: Conviction": 'rgba(76, 175, 80, 0.5)',
  "Tom Clancy's Splinter Cell: Blacklist": 'rgba(205, 220, 57, 0.5)',
  "MechWarrior 5: Mercenaries": 'rgba(255, 235, 59, 0.5)',
  "GunZ: The Duel": 'rgba(255, 193, 7, 0.5)',
  "Codenames": 'rgba(33, 33, 33, 0.5)',
  "Friday Night Funkin'": 'rgba(156, 39, 176, 0.5)',
  "World of Warships: Legends": 'rgba(0, 188, 212, 0.5)',
  "Armored Warfare": 'rgba(139, 195, 74, 0.5)',
  "Counter-Strike: 1.6": 'rgba(205, 220, 57, 0.5)',
  "Arma Reforger": 'rgba(255, 235, 59, 0.5)',
  "Alan Wake II": 'rgba(255, 193, 7, 0.5)',
  "Arcade Paradise": 'rgba(255, 87, 34, 0.5)',
  ".Hack//Infection": 'rgba(121, 85, 72, 0.5)',
  "Dead Island 2": 'rgba(33, 150, 243, 0.5)',
  "Pokémon Trading Card Game": 'rgba(244, 67, 54, 0.5)',
  "Magic: The Gathering Arena": 'rgba(76, 175, 80, 0.5)',
  "Century: Age of Ashes": 'rgba(205, 220, 57, 0.5)',
  "Marvel Snap": 'rgba(255, 235, 59, 0.5)',
  "The Binding of Isaac: Repentance": 'rgba(255, 193, 7, 0.5)',
  "Inscryption": 'rgba(33, 33, 33, 0.5)',
  "Crash Bandicoot 4: It's About Time": 'rgba(156, 39, 176, 0.5)',
  "Shredders": 'rgba(0, 188, 212, 0.5)',
  "Slime Rancher 2": 'rgba(139, 195, 74, 0.5)',
  "Nintendo Switch": 'rgba(205, 220, 57, 0.5)',
  "Turnip Boy Robs a Bank": 'rgba(255, 235, 59, 0.5)',
  "Warhammer 40,000: Dawn of War III": 'rgba(255, 193, 7, 0.5)',
  "Halo: Combat Evolved": 'rgba(255, 87, 34, 0.5)',
  "BioShock Infinite": 'rgba(121, 85, 72, 0.5)',
  "Eternal Return": 'rgba(33, 150, 243, 0.5)',
  "Combat Master": 'rgba(244, 67, 54, 0.5)',
  "Skies of Arcadia": 'rgba(76, 175, 80, 0.5)',
  "Persona 5 Royal": 'rgba(205, 220, 57, 0.5)',
  "Dragon Ball Z: Budokai Tenkaichi": 'rgba(255, 235, 59, 0.5)',
  "Dragon Ball Z: Budokai Tenkaichi 3": 'rgba(255, 193, 7, 0.5)',
  "Granblue Fantasy: Relink": 'rgba(33, 33, 33, 0.5)',
  "Honkai: Star Rail": 'rgba(156, 39, 176, 0.5)',
  "Nights of Azure": 'rgba(0, 188, 212, 0.5)',
  "Call of Duty 3": 'rgba(139, 195, 74, 0.5)',
  "Madden NFL 2005": 'rgba(205, 220, 57, 0.5)',
  "Shadows of Doubt": 'rgba(255, 235, 59, 0.5)',
  "Outward": 'rgba(255, 193, 7, 0.5)',
  "Prince of Persia: Warrior Within": 'rgba(255, 87, 34, 0.5)',
  "Prince of Persia: The Two Thrones": 'rgba(121, 85, 72, 0.5)',
  "Dark Souls: Remastered": 'rgba(33, 150, 243, 0.5)',
  "Dead or Alive Xtreme Venus Vacation": 'rgba(244, 67, 54, 0.5)',
  "Goddess of Victory: Nikke": 'rgba(76, 175, 80, 0.5)',
  "The King of Fighters 2002": 'rgba(205, 220, 57, 0.5)',
  "Saint Seiya: Awakening": 'rgba(255, 235, 59, 0.5)',
  "Jet Set Radio Future": 'rgba(255, 193, 7, 0.5)',
  "GRIS": 'rgba(33, 33, 33, 0.5)',
  "The Callisto Protocol": 'rgba(156, 39, 176, 0.5)',
  "Coffee Talk": 'rgba(0, 188, 212, 0.5)',
  "3on3 FreeStyle: Rebound": 'rgba(139, 195, 74, 0.5)',
  "Perfect Heist 2": 'rgba(205, 220, 57, 0.5)',
  "Uncharted: Drake's Fortune": 'rgba(255, 235, 59, 0.5)',
  "Kingdom Hearts HD 1.5 + 2.5 Remix": 'rgba(255, 193, 7, 0.5)',
  "Iron Lung": 'rgba(33, 33, 33, 0.5)',
  "Need for Speed: Underground": 'rgba(156, 39, 176, 0.5)',
  "Medieval Dynasty": 'rgba(0, 188, 212, 0.5)',
  "Halo 3": 'rgba(139, 195, 74, 0.5)',
  "Star Wars: Knights of the Old Republic II": 'rgba(205, 220, 57, 0.5)',
  "Dragon Quest XI: Echoes of an Elusive Age": 'rgba(255, 235, 59, 0.5)',
  "Resident Evil 6": 'rgba(255, 193, 7, 0.5)',
  "Final Fantasy VII Remake": 'rgba(33, 33, 33, 0.5)',
  "Ghost Exorcism Inc.": 'rgba(156, 39, 176, 0.5)',
  "Writing": 'rgba(0, 188, 212, 0.5)',
  "Hydroneer": 'rgba(139, 195, 74, 0.5)',
  "HD Poker: Texas Holdem Casino": 'rgba(205, 220, 57, 0.5)',
  "Street Fighter 6": 'rgba(255, 235, 59, 0.5)',
  "BioShock": 'rgba(255, 193, 7, 0.5)',
  "Board Games": 'rgba(33, 33, 33, 0.5)',
  "Wii Sports Resort": 'rgba(156, 39, 176, 0.5)',
  "SCP: Secret Laboratory": 'rgba(0, 188, 212, 0.5)',
  "Call of Duty: WWII": 'rgba(139, 195, 74, 0.5)',
  "Words on Stream": 'rgba(205, 220, 57, 0.5)',
  "Space Haven": 'rgba(255, 235, 59, 0.5)',
  "Final Fantasy VI": 'rgba(255, 193, 7, 0.5)',
  "Project Wingman": 'rgba(33, 33, 33, 0.5)',
  "Men of War II": 'rgba(156, 39, 176, 0.5)',
  "Business Tour": 'rgba(0, 188, 212, 0.5)',
  "Maid of Sker": 'rgba(139, 195, 74, 0.5)',
  "The Outlast Trials": 'rgba(205, 220, 57, 0.5)',
  "Slime Rancher": 'rgba(255, 235, 59, 0.5)',
  "F1 23": 'rgba(255, 193, 7, 0.5)',
  "Pac-Man World Re-Pac": 'rgba(33, 33, 33, 0.5)',
  "IdleOn: The Idle MMO": 'rgba(156, 39, 176, 0.5)',
  "Wordle": 'rgba(0, 188, 212, 0.5)',
  "The Texas Chain Saw Massacre": 'rgba(139, 195, 74, 0.5)',
  "Kalonline": 'rgba(205, 220, 57, 0.5)',
  "People Playground": 'rgba(255, 235, 59, 0.5)',
  "No Time to Relax": 'rgba(255, 193, 7, 0.5)',
  "The Godfather": 'rgba(33, 33, 33, 0.5)',
  "Fallout 2": 'rgba(156, 39, 176, 0.5)',
  "My Hero Ultra Rumble": 'rgba(0, 188, 212, 0.5)',
  "After Stream": 'rgba(139, 195, 74, 0.5)',
  "Amnesia: The Bunker": 'rgba(205, 220, 57, 0.5)',
  "Halls of Torment": 'rgba(255, 235, 59, 0.5)',
  "Standoff 2": 'rgba(255, 193, 7, 0.5)',
  "Sherlock Holmes: Crimes and Punishments Redux": 'rgba(33, 33, 33, 0.5)',
  "Backrooms: Escape Together": 'rgba(156, 39, 176, 0.5)',
  "Only Up!": 'rgba(0, 188, 212, 0.5)',
  "StarCraft: Brood War": 'rgba(139, 195, 74, 0.5)',
  "Snowbreak: Containment Zone": 'rgba(205, 220, 57, 0.5)',
  "Raid: Shadow Legends": 'rgba(255, 235, 59, 0.5)',
  "Parasite Eve": 'rgba(255, 193, 7, 0.5)',
  "Garena Free Fire Max": 'rgba(33, 33, 33, 0.5)',
  "Resident Evil 4 Remake": 'rgba(156, 39, 176, 0.5)',
  "Dota": 'rgba(0, 188, 212, 0.5)',
  "Stick Fight: The Game": 'rgba(139, 195, 74, 0.5)',
  "Bendy and the Ink Machine": 'rgba(205, 220, 57, 0.5)',
  "Mist Survival": 'rgba(255, 235, 59, 0.5)',
  "Pro Evolution Soccer 6": 'rgba(255, 193, 7, 0.5)',
  "Vein": 'rgba(33, 33, 33, 0.5)',
  "Metin 2": 'rgba(156, 39, 176, 0.5)',
  "Grand Theft Auto": 'rgba(0, 188, 212, 0.5)',
  "Grand Theft Auto: San Andreas - The Definitive Edition": 'rgba(139, 195, 74, 0.5)',
  "Grand Theft Auto: Liberty City Stories": 'rgba(205, 220, 57, 0.5)',
  "Grand Theft Auto: Vice City Stories": 'rgba(255, 235, 59, 0.5)',
  "Grand Theft Auto: Chinatown Wars": 'rgba(255, 193, 7, 0.5)',
  "Doom II: Hell on Earth": 'rgba(33, 33, 33, 0.5)',
  "Dragonheir: Silent Gods": 'rgba(156, 39, 176, 0.5)',
  "Call of Duty 2": 'rgba(0, 188, 212, 0.5)',
  "Pro Soccer Online": 'rgba(139, 195, 74, 0.5)',
  "Argentum Online": 'rgba(205, 220, 57, 0.5)',
  "Lies of P": 'rgba(255, 235, 59, 0.5)',
  "Dragon Ball Z: The Legacy of Goku": 'rgba(255, 193, 7, 0.5)',
  "Cry of Fear": 'rgba(33, 33, 33, 0.5)',
  "One Piece: Bounty Rush": 'rgba(156, 39, 176, 0.5)',
  "Into the Radius": 'rgba(0, 188, 212, 0.5)',
  "The Crew: Motorfest": 'rgba(139, 195, 74, 0.5)',
  "Mini Royale": 'rgba(205, 220, 57, 0.5)',
  "Halo Wars 2": 'rgba(255, 235, 59, 0.5)',
  "Warhammer 40,000: Gladius - Relics of War": 'rgba(255, 193, 7, 0.5)',
  "Guacamelee! 2": 'rgba(33, 33, 33, 0.5)',
  "The Lord of the Rings: The Two Towers": 'rgba(156, 39, 176, 0.5)',
  "Game Development": 'rgba(0, 188, 212, 0.5)',
  "Waven": 'rgba(139, 195, 74, 0.5)',
  "Fire Emblem: Three Houses": 'rgba(205, 220, 57, 0.5)',
  "Throne and Liberty": 'rgba(255, 235, 59, 0.5)',
  "Tarisland": 'rgba(255, 193, 7, 0.5)',
  "Ratchet & Clank: Size Matters": 'rgba(33, 33, 33, 0.5)',
  "BattleBit Remastered": 'rgba(156, 39, 176, 0.5)',
  "The First Descendant": 'rgba(0, 188, 212, 0.5)',
  "LEGO Star Wars: The Complete Saga": 'rgba(139, 195, 74, 0.5)',
  "Six Days in Fallujah": 'rgba(205, 220, 57, 0.5)',
  "The Sims Life Stories": 'rgba(255, 235, 59, 0.5)',
  "Mortal Kombat 1": 'rgba(255, 193, 7, 0.5)',
  "World of Warcraft Classic": 'rgba(33, 33, 33, 0.5)',
  "Paleo Pines": 'rgba(156, 39, 176, 0.5)',
  "Limbo": 'rgba(0, 188, 212, 0.5)',
  "Killer Klowns from Outer Space: The Game": 'rgba(139, 195, 74, 0.5)',
  "Harry Potter: Magic Awakened": 'rgba(205, 220, 57, 0.5)',
  "Company of Heroes 2": 'rgba(255, 235, 59, 0.5)',
  "Warriors Orochi 4 Ultimate": 'rgba(255, 193, 7, 0.5)',
  "FINAL FANTASY": 'rgba(33, 33, 33, 0.5)',
  "Fortnite Zero Build": 'rgba(156, 39, 176, 0.5)',
  "Rennsport": 'rgba(0, 188, 212, 0.5)',
  "Project: BloodStrike": 'rgba(139, 195, 74, 0.5)',
  "F.I.S.T.: Forged In Shadow Torch": 'rgba(205, 220, 57, 0.5)',
  "Goat Simulator 3": 'rgba(255, 235, 59, 0.5)',
  "Live Music": 'rgba(255, 193, 7, 0.5)',
  "Uncrashed: FPV Drone Simulator": 'rgba(33, 33, 33, 0.5)',
  "Blooming Business: Casino": 'rgba(156, 39, 176, 0.5)',
  "Resident Evil 2 (2019)": 'rgba(0, 188, 212, 0.5)',
  "Pixel Strike 3D": 'rgba(139, 195, 74, 0.5)',
  "Grand Chase Classic": 'rgba(205, 220, 57, 0.5)',
  "Chill Corner": 'rgba(255, 235, 59, 0.5)',
  "Eternal Card Game": 'rgba(255, 193, 7, 0.5)',
  "Remnant II": 'rgba(33, 33, 33, 0.5)',
  "Fortnite: Save the World": 'rgba(156, 39, 176, 0.5)',
  "The Walking Dead: Season One": 'rgba(0, 188, 212, 0.5)',
  "Mario & Luigi: Dream Team": 'rgba(139, 195, 74, 0.5)',
  "Final Fantasy VII: Ever Crisis": 'rgba(205, 220, 57, 0.5)',
  "Helldivers II": 'rgba(255, 235, 59, 0.5)',
  "Sly 2: Band of Thieves": 'rgba(255, 193, 7, 0.5)',
  "Sly 3: Honor Among Thieves": 'rgba(33, 33, 33, 0.5)',
  "Islanders": 'rgba(156, 39, 176, 0.5)',
  "Cyber Security": 'rgba(0, 188, 212, 0.5)',
  "Zombie Survival Game Online": 'rgba(139, 195, 74, 0.5)',
  "NBA 2K24": 'rgba(205, 220, 57, 0.5)',
  "Operation Wolf Returns: First Mission": 'rgba(255, 235, 59, 0.5)',
  "Youtubers Life 2": 'rgba(255, 193, 7, 0.5)',
  "Naruto: Rise of a Ninja": 'rgba(33, 33, 33, 0.5)',
  "Dead Rising": 'rgba(156, 39, 176, 0.5)',
  "Gamer Shop Simulator": 'rgba(0, 188, 212, 0.5)',
  "Digimon World": 'rgba(139, 195, 74, 0.5)',
  "Age of Empires: Definitive Edition": 'rgba(205, 220, 57, 0.5)',
  "Hitman 2": 'rgba(255, 235, 59, 0.5)',
  "World of Warcraft: Cataclysm": 'rgba(255, 193, 7, 0.5)',
  "Auto Mechanic": 'rgba(33, 33, 33, 0.5)',
  "Palia": 'rgba(156, 39, 176, 0.5)',
  "Trading Cards": 'rgba(0, 188, 212, 0.5)',
  "Hell Let Loose: Devotion to Duty": 'rgba(139, 195, 74, 0.5)',
  "Sea of Thieves: The Legend of Monkey Island": 'rgba(205, 220, 57, 0.5)',
  "Tekken 8": 'rgba(255, 235, 59, 0.5)',
  "Hyper Dragon Ball Z": 'rgba(255, 193, 7, 0.5)',
  "Marblous": 'rgba(33, 33, 33, 0.5)',
  "Sonic Colors: Ultimate": 'rgba(156, 39, 176, 0.5)',
  "Shining Force": 'rgba(0, 188, 212, 0.5)',
  "Blue Fire": 'rgba(139, 195, 74, 0.5)',
  "Pharaoh: A New Era": 'rgba(205, 220, 57, 0.5)',
  "Stampede Racing Royale": 'rgba(255, 235, 59, 0.5)',
  "Marvel's Spider-Man 2": 'rgba(255, 193, 7, 0.5)',
  "Pump it Up Phoenix": 'rgba(33, 33, 33, 0.5)',
  "Devil May Cry 2": 'rgba(156, 39, 176, 0.5)',
  "Payday 3": 'rgba(0, 188, 212, 0.5)',
  "Thief II: The Metal Age": 'rgba(139, 195, 74, 0.5)',
  "Farming Simulator 23": 'rgba(205, 220, 57, 0.5)',
  "Starfield": 'rgba(255, 235, 59, 0.5)',
  "The Complex: Expedition": 'rgba(255, 193, 7, 0.5)',
  "Stronghold Crusader HD": 'rgba(33, 33, 33, 0.5)',
  "Call of Duty: Modern Warfare III": 'rgba(156, 39, 176, 0.5)',
  "Call of Duty: Black Ops Zombies": 'rgba(0, 188, 212, 0.5)',
  "Project Reality: BF2": 'rgba(139, 195, 74, 0.5)',
  "Robin Hood: Builders of Sherwood": 'rgba(205, 220, 57, 0.5)',
  "Madden NFL 24": 'rgba(255, 235, 59, 0.5)',
  "The Karaoke": 'rgba(255, 193, 7, 0.5)',
  "Mass Effect: Andromeda": 'rgba(33, 33, 33, 0.5)',
  "The Walking Dead: Season Two": 'rgba(156, 39, 176, 0.5)',
  "Drunken Wrestlers 2": 'rgba(0, 188, 212, 0.5)',
  "EA Sports FC 24": 'rgba(139, 195, 74, 0.5)',
  "Car Parking Multiplayer": 'rgba(205, 220, 57, 0.5)',
  "Barony": 'rgba(255, 235, 59, 0.5)',
  "Stream Raiders": 'rgba(255, 193, 7, 0.5)',
  "Resident Evil Outbreak": 'rgba(33, 33, 33, 0.5)',
  "World of Warcraft Classic Hardcore": 'rgba(156, 39, 176, 0.5)',
  "Sunkenland": 'rgba(0, 188, 212, 0.5)',
  "Sword Art Online: Integral Factor": 'rgba(139, 195, 74, 0.5)',
  "Strafe": 'rgba(205, 220, 57, 0.5)',
  "Call of Duty: Warzone - Mobile": 'rgba(255, 235, 59, 0.5)',
  "Ride 5": 'rgba(255, 193, 7, 0.5)',
  "Fire Emblem: Path of Radiance": 'rgba(33, 33, 33, 0.5)',
  "Haxball": 'rgba(156, 39, 176, 0.5)',
  "Anime Squad": 'rgba(0, 188, 212, 0.5)',
  "Bloodstained: Ritual of the Night": 'rgba(139, 195, 74, 0.5)',
  "My Little Pony: A Maretime Bay Adventure": 'rgba(205, 220, 57, 0.5)',
  "Tom Clancy's Rainbow Six: Vegas": 'rgba(255, 235, 59, 0.5)',
  "WWE SmackDown vs. Raw 2008": 'rgba(255, 193, 7, 0.5)',
  "Sucker for Love: Date to Die For": 'rgba(33, 33, 33, 0.5)',
  "Eraser": 'rgba(156, 39, 176, 0.5)',
  "Moving Out 2": 'rgba(0, 188, 212, 0.5)',
  "Party Animals": 'rgba(139, 195, 74, 0.5)',
  "NHL 24": 'rgba(205, 220, 57, 0.5)',
  "House Builder": 'rgba(255, 235, 59, 0.5)',
  "Mortal Kombat 9": 'rgba(255, 193, 7, 0.5)',
  "Uncharted: The Lost Legacy": 'rgba(33, 33, 33, 0.5)',
  "Rakion": 'rgba(156, 39, 176, 0.5)',
  "Call of Duty 1": 'rgba(0, 188, 212, 0.5)',
  "3D Modeling": 'rgba(139, 195, 74, 0.5)',
  "Bodycam": 'rgba(205, 220, 57, 0.5)',
  "FC Mobile": 'rgba(255, 235, 59, 0.5)',
  "Valorant: Mobile": 'rgba(255, 193, 7, 0.5)',
  "eFootball 2024": 'rgba(33, 33, 33, 0.5)',
  "Ark: Survival Ascended": 'rgba(156, 39, 176, 0.5)',
  "Star Wars: Hunters": 'rgba(0, 188, 212, 0.5)',
  "Unsorted Horror": 'rgba(139, 195, 74, 0.5)',
  "Enshrouded": 'rgba(205, 220, 57, 0.5)',
  "Assassin's Creed Mirage": 'rgba(255, 235, 59, 0.5)',
  "Forza Motorsport": 'rgba(255, 193, 7, 0.5)',
  "Sonic Forces": 'rgba(33, 33, 33, 0.5)',
  "Total War: Pharaoh": 'rgba(156, 39, 176, 0.5)',
  "Lords of the Fallen": 'rgba(0, 188, 212, 0.5)',
  "Phoenix Wright: Ace Attorney Trilogy": 'rgba(139, 195, 74, 0.5)',
  "Need for Speed: Deluxe Edition": 'rgba(205, 220, 57, 0.5)',
  "Football Manager 2024": 'rgba(255, 235, 59, 0.5)',
  "Shin Megami Tensei V": 'rgba(255, 193, 7, 0.5)',
  "Lineage II": 'rgba(33, 33, 33, 0.5)',
  "Just Dance 2024 Edition": 'rgba(156, 39, 176, 0.5)',
  "Sneak Out": 'rgba(139, 195, 74, 0.5)',
  "Sker Ritual": 'rgba(205, 220, 57, 0.5)',
  "EA Sports WRC": 'rgba(255, 235, 59, 0.5)',
  "Rhapsody: A Musical Adventure": 'rgba(255, 193, 7, 0.5)',
  "Lethal Company": 'rgba(33, 33, 33, 0.5)',
  "Operation 7": 'rgba(156, 39, 176, 0.5)',
  "Avatar: Frontiers of Pandora": 'rgba(0, 188, 212, 0.5)',
  "Pax Dei": 'rgba(139, 195, 74, 0.5)',
  "Naruto x Boruto: Ultimate Ninja Storm Connections": 'rgba(205, 220, 57, 0.5)',
  "Suicide Squad: Kill the Justice League": 'rgba(255, 235, 59, 0.5)',
  "The Last Faith": 'rgba(255, 193, 7, 0.5)',
  "Souldiers": 'rgba(33, 33, 33, 0.5)',
  "Zenless Zone Zero": 'rgba(156, 39, 176, 0.5)',
  "Junkyard Simulator": 'rgba(0, 188, 212, 0.5)',
  "The Talos Principle II": 'rgba(139, 195, 74, 0.5)',
  "Pokémon FireRed Version": 'rgba(205, 220, 57, 0.5)',
  "Warcraft III: The Frozen Throne": 'rgba(255, 235, 59, 0.5)',
  "Once Human": 'rgba(255, 193, 7, 0.5)',
  "The House of Da Vinci 3": 'rgba(33, 33, 33, 0.5)',
  "Evil Lands": 'rgba(156, 39, 176, 0.5)',
  "LEGO Fortnite": 'rgba(0, 188, 212, 0.5)',
  "Marvel's Midnight Suns": 'rgba(139, 195, 74, 0.5)',
  "Pokémon Unbound": 'rgba(205, 220, 57, 0.5)',
  "Palworld": 'rgba(255, 235, 59, 0.5)',
  "I'm Only Sleeping": 'rgba(255, 193, 7, 0.5)',
  "Escape from Tarkov: Arena": 'rgba(33, 33, 33, 0.5)',
  "One-Armed Robber": 'rgba(156, 39, 176, 0.5)',
  "Only Up 2": 'rgba(0, 188, 212, 0.5)',
  "Cine2Nerdle": 'rgba(139, 195, 74, 0.5)',
  "Super Bomberman R 2": 'rgba(205, 220, 57, 0.5)',
  "Senua's Saga: Hellblade II": 'rgba(255, 235, 59, 0.5)',
  "Reaction": 'rgba(255, 193, 7, 0.5)',
  "Magicraft": 'rgba(33, 33, 33, 0.5)',
  "Songs of Syx": 'rgba(156, 39, 176, 0.5)',
  "Drug Dealer Simulator 2": 'rgba(0, 188, 212, 0.5)',
  "Cat Palette: Merging Colorful Kittens": 'rgba(139, 195, 74, 0.5)',
  "Movierooms": 'rgba(205, 220, 57, 0.5)',
  "Artisan TD": 'rgba(255, 235, 59, 0.5)',
  "Granblue Fantasy Versus: Rising": 'rgba(255, 193, 7, 0.5)',
  "Kebab Chefs!: Restaurant Simulator": 'rgba(33, 33, 33, 0.5)',
  "School of Dragons: How to Train Your Dragon": 'rgba(156, 39, 176, 0.5)',
  "Eiyuden Chronicle: Hundred Heroes": 'rgba(0, 188, 212, 0.5)',
  "Persona 3 Reload": 'rgba(139, 195, 74, 0.5)',
  "Modern Warships": 'rgba(205, 220, 57, 0.5)',
  "Silent Hill: The Short Message": 'rgba(255, 235, 59, 0.5)',
  "Blood Strike": 'rgba(255, 193, 7, 0.5)',
  "Jujutsu Kaisen: Cursed Clash": 'rgba(33, 33, 33, 0.5)',
  "Foamstars": 'rgba(156, 39, 176, 0.5)',
  "Homeworld": 'rgba(0, 188, 212, 0.5)',
  "Dungeonborne": 'rgba(139, 195, 74, 0.5)',
  "Supermarket Simulator": 'rgba(205, 220, 57, 0.5)',
  "Pacific Drive": 'rgba(255, 235, 59, 0.5)',
  "Metalstorm": 'rgba(255, 193, 7, 0.5)',
  "Final Fantasy VII Rebirth": 'rgba(33, 33, 33, 0.5)',
  "Shrapnel": 'rgba(156, 39, 176, 0.5)',
  "Warzone": 'rgba(0, 188, 212, 0.5)',
  "Forza Horizon": 'rgba(139, 195, 74, 0.5)',
  "Soulmask": 'rgba(205, 220, 57, 0.5)',
  "MLB The Show 24": 'rgba(255, 235, 59, 0.5)',
  "Lost in Play": 'rgba(255, 193, 7, 0.5)',
  "WWE 2K24": 'rgba(33, 33, 33, 0.5)',
  "Reveil": 'rgba(156, 39, 176, 0.5)',
  "We are Football 2024": 'rgba(0, 188, 212, 0.5)',
  "Call of Duty Online": 'rgba(139, 195, 74, 0.5)',
  "Dragon's Dogma II": 'rgba(205, 220, 57, 0.5)',
  "Night Crows": 'rgba(255, 235, 59, 0.5)',
  "Outcast: A New Beginning": 'rgba(255, 193, 7, 0.5)',
  "Silent Breath": 'rgba(33, 33, 33, 0.5)',
  "Panicore": 'rgba(156, 39, 176, 0.5)',
  "Beach Club Simulator 2024": 'rgba(0, 188, 212, 0.5)',
  "Squad Busters": 'rgba(139, 195, 74, 0.5)',
  "Solo Leveling: Arise": 'rgba(205, 220, 57, 0.5)',
  "AFK Journey": 'rgba(255, 235, 59, 0.5)',
  "Marvel Rivals": 'rgba(255, 193, 7, 0.5)',
  "Content Warning": 'rgba(33, 33, 33, 0.5)',
  "No Rest for the Wicked": 'rgba(156, 39, 176, 0.5)',
  "Manor Lords": 'rgba(0, 188, 212, 0.5)',
  "Incursion Red River": 'rgba(139, 195, 74, 0.5)',
  "Sherlock Holmes: Chapter One": 'rgba(205, 220, 57, 0.5)',
  "Gray Zone Warfare": 'rgba(255, 235, 59, 0.5)',
  "Another Crab's Treasure": 'rgba(255, 193, 7, 0.5)',
  "eFootball PES 2021": 'rgba(33, 33, 33, 0.5)',
  "Project L33t": 'rgba(156, 39, 176, 0.5)',
  "Casino Simulator": 'rgba(0, 188, 212, 0.5)',
  "PokéRogue": 'rgba(139, 195, 74, 0.5)',
  "Casino Boss Simulator": 'rgba(205, 220, 57, 0.5)',
  "EA Sports F1 24": 'rgba(255, 235, 59, 0.5)',
  "Game with Friends": 'rgba(255, 193, 7, 0.5)',
  "Nine Sols": 'rgba(33, 33, 33, 0.5)',
  "Faint Call": 'rgba(156, 39, 176, 0.5)',
  "Sniper Elite V2 Remastered": 'rgba(0, 188, 212, 0.5)',
  "ContractVille": 'rgba(139, 195, 74, 0.5)',
  "Urban Explorer": 'rgba(205, 220, 57, 0.5)',
  "Platform 8": 'rgba(255, 235, 59, 0.5)',
  "Wrestling Empire": 'rgba(255, 193, 7, 0.5)',
  "Murky Divers": 'rgba(33, 33, 33, 0.5)',
  "Still Wakes the Deep": 'rgba(156, 39, 176, 0.5)',
  "Chained Together": 'rgba(0, 188, 212, 0.5)',
  ".Hack//G.U. Last Recode": 'rgba(139, 195, 74, 0.5)',
  "Gears of War: Ultimate Edition": 'rgba(205, 220, 57, 0.5)',
  "Chat Roulette": 'rgba(255, 235, 59, 0.5)',
  "IRL": 'rgba(255, 193, 7, 0.5)',
  "Rawmen": 'rgba(33, 33, 33, 0.5)',
  "Pilgrim": 'rgba(156, 39, 176, 0.5)',
  "Beyond a Steel Sky": 'rgba(0, 188, 212, 0.5)',
  "Rocket Racing": 'rgba(139, 195, 74, 0.5)',
  "Luigi's Mansion 2 HD": 'rgba(205, 220, 57, 0.5)',
  "Dragon Ball: Sparking! Zero": 'rgba(255, 235, 59, 0.5)',
  "Coloring Game": 'rgba(255, 193, 7, 0.5)',
  "Swords and Sandals": 'rgba(33, 33, 33, 0.5)',
  "Delta Force: Hawk Ops": 'rgba(156, 39, 176, 0.5)',
  "EA Sports College Football 25 (2024)": 'rgba(0, 188, 212, 0.5)',
  "EA Sports College Football 25": 'rgba(139, 195, 74, 0.5)',
  "Teenage Mutant Ninja Turtles: Splintered Fate": 'rgba(205, 220, 57, 0.5)',
  "The Crust": 'rgba(255, 235, 59, 0.5)',
  "Flintlock: The Siege of Dawn": 'rgba(255, 193, 7, 0.5)',
  "Kunitsu-Gami: Path of the Goddess": 'rgba(33, 33, 33, 0.5)',
  "Dungeons of Hinterberg": 'rgba(156, 39, 176, 0.5)',
  "Dark Hours": 'rgba(0, 188, 212, 0.5)',
  "Conscript": 'rgba(139, 195, 74, 0.5)',
  "Metal Slug Awakening": 'rgba(205, 220, 57, 0.5)',
};

export const getCategoryColor = (category) => {
  return categories[category] || 'rgba(75,192,192,0.2)';
};

const CategoryColors = () => {
  return (
    <div>
      {Object.keys(categories).map((category) => (
        <div key={category} style={{ color: getCategoryColor(category) }}>
          {category}
        </div>
      ))}
    </div>
  );
};
  

export default CategoryColors;
