// src/pages/BrowsePage/BrowsePage.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './BrowsePage.module.css';

const BrowsePage = () => {
  const [streamers, setStreamers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [streamersPerPage] = useState(10);
  const [sortField, setSortField] = useState('username');
  const [sortOrder, setSortOrder] = useState('asc');
  const [timeframe, setTimeframe] = useState('daily');
  const navigate = useNavigate();

  const fetchStreamers = useCallback(() => {
    fetch(`https://backend.streamerstats.com/kick/browse?timeframe=${timeframe}&sortField=${sortField}&sortOrder=${sortOrder}&page=${currentPage}&limit=${streamersPerPage}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Fetched data:', data);
        if (data) {
          setStreamers(data);
        } else {
          console.error('Unexpected data format:', data);
          setStreamers([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching streamers:', error);
        setStreamers([]);
      });
  }, [timeframe, sortField, sortOrder, currentPage, streamersPerPage]);

  useEffect(() => {
    fetchStreamers();
  }, [fetchStreamers]);

  const handleTimeframeChange = (e) => {
    setTimeframe(e.target.value);
    setCurrentPage(1);
    fetchStreamers();
  };

  const handleSortFieldChange = (e) => {
    setSortField(e.target.value);
    setSortOrder('asc');
    setCurrentPage(1);
    fetchStreamers();
  };

  const handleRowClick = (username) => {
    navigate(`/kick/${username}`);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  console.log(streamers)
  return (
    <div className={styles.browseContainer}>
      <h1>Browse Streamers</h1>
      <div className={styles.toolbar}>
        <div className={styles.timeframeSelector}>
          <label htmlFor="timeframe">Timeframe:</label>
          <select
            id="timeframe"
            value={timeframe}
            onChange={handleTimeframeChange}
          >
            <option value="hourly">Hourly</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </select>
        </div>
        <div className={styles.sortSelector}>
          <label htmlFor="sortField">Sort By:</label>
          <select
            id="sortField"
            value={sortField}
            onChange={handleSortFieldChange}
          >
            <option value="username">Username</option>
            <option value="average_viewers">Average Viewers</option>
            <option value="peak_viewers">Peak Viewers</option>
            <option value="followers">Followers</option>
            <option value="total_subs">Total Subs</option>
            <option value="category">Category</option>
            <option value="average_messages_per_minute">Avg Messages/Min</option>
          </select>
        </div>
      </div>
      <table className={styles.streamersTable}>
        <thead>
          <tr>
            <th>Username</th>
            <th>Average Viewers</th>
            <th>Peak Viewers</th>
            <th>Followers</th>
            <th>Total Subs</th>
            <th>Category</th>
            <th>Avg Messages/Min</th>
          </tr>
        </thead>
        <tbody>
          {streamers.map((streamer) => (
            <tr key={streamer.username} onClick={() => handleRowClick(streamer.username)} className={styles.clickableRow}>
              <td>{streamer.username}</td>
              <td>{streamer.average_viewers}</td>
              <td>{streamer.peak_viewers}</td>
              <td>{streamer.followers}</td>
              <td>{streamer.total_subs}</td>
              <td>{streamer.category}</td>
              <td>{streamer.average_messages_per_minute}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.pagination}>
        {Array.from({ length: Math.ceil(streamers.length / streamersPerPage) }, (_, index) => (
          <button key={index + 1} onClick={() => paginate(index + 1)}>
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default BrowsePage;
