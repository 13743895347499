import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import styles from './ChatLogs.module.css';

Modal.setAppElement('#root');

const ChatLogs = () => {
  const { username } = useParams();
  const [chats, setChats] = useState([]);
  const [expandedChat, setExpandedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState('');
  const [currentUser, setCurrentUser] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingMilliseconds, setLoadingMilliseconds] = useState(0);
  const [noChatsFound, setNoChatsFound] = useState(false);

  const chatMessagesRef = useRef(null);
  const navigate = useNavigate();

  
  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingMilliseconds((prev) => prev + 1);
    }, 1);
  
    fetch(`https://backend.streamerstats.com/kick/chat/${username}`)
      .then((response) => response.json())
      .then((data) => {
        if (data === null) {
          setNoChatsFound(true);
        } else {
          setChats(data);
        }
        setLoading(false);
        clearInterval(interval);
      });
    return () => clearInterval(interval);
  }, [username]);
  

  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  };

  const setInitialDate = (messages) => {
    if (messages && messages.length > 0) {
      const firstMessageDate = formatDate(messages[0].timestamp);
      setCurrentDate(firstMessageDate);
    }
  };

  const handleExpandChat = (chatId, user) => {
    setCurrentUser(user);
    if (expandedChat === chatId) {
      setExpandedChat(null);
      setMessages([]);
      setIsModalOpen(false);
      chatMessagesRef.current.removeEventListener('scroll', handleScroll);
    } else {
      setIsModalOpen(false); 
      setExpandedChat(null); 
      setMessages([]); 
      if (chatMessagesRef.current) {
        chatMessagesRef.current.removeEventListener('scroll', handleScroll);
      }
      
      setExpandedChat(chatId);
      fetch(`https://backend.streamerstats.com/kick/chat/${username}/${chatId}`)
        .then((response) => response.json())
        .then((data) => {
          setMessages(data);
          setInitialDate(data);
          setIsModalOpen(true);
        });
    }
  };

  const handleScroll = () => {
    if (chatMessagesRef.current) {
      const chatMessagesDiv = chatMessagesRef.current;
      const scrollTop = chatMessagesDiv.scrollTop;
      const children = chatMessagesDiv.children;
  
      let topVisibleDate = '';
  
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child.offsetTop + child.offsetHeight > scrollTop) {
          const dateDiv = child.querySelector(`.${styles.dateHeader}`);
          if (dateDiv) {
            topVisibleDate = dateDiv.dataset.date;
            break;
          }
        }
      }
  
      if (topVisibleDate && topVisibleDate !== currentDate) {
        setCurrentDate(topVisibleDate);
      }
    }
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString();
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
  };

  const groupMessagesByDate = (messages) => {
    if (!messages || messages.length === 0) return {};
    return messages.reduce((acc, message) => {
      const date = formatDate(message.timestamp);
      if (!acc[date]) acc[date] = [];
      acc[date].push(message);
      return acc;
    }, {});
  };

  const groupedMessages = groupMessagesByDate(messages);

  const handleViewStats = () => {
    navigate(`/kick/${currentUser}`);
  };

  if (noChatsFound) {
    return (
      <div className={styles.noChatsContainer}>
        <h1>No chats found for user {username}</h1>
      </div>
    );
  }
  
  const formattedLoadingSeconds = (loadingMilliseconds / 1000).toFixed(3);

  return (
    loading ? (
      <div className={styles.loadingContainer}>
        <div className={styles.loadingOverlay}>
          <img src="/79209fb8-301c-46ef-8a81-bfa57398a0c0.webp" alt="Loading" className={styles.loadingImage} />
          <div className={styles.loadingTextContainer}>
            <div className={styles.loadingText}>Checking millions of messages... ({formattedLoadingSeconds} seconds)</div>
          </div>
        </div>
      </div>
    ) : (
    <div className={styles.profileContainer}>
      <h1>User Chats</h1>
      <div className={styles.chatList}>
        {chats && chats.map((chat) => (
          <div key={chat.chatroom_id} className={styles.chatCard} onClick={() => handleExpandChat(chat.chatroom_id, chat.username)}>
            <div className={styles.chatHeader}>
              <img src={chat.profile_pic} alt={`${chat.username}'s profile`} className={styles.profilePic} />
              <h2>{chat.username}</h2>
            </div>
          </div>
        ))}
      </div>
      <Modal
        isOpen={isModalOpen}
        onAfterOpen={() => {
          scrollToBottom();
          chatMessagesRef.current.addEventListener('scroll', handleScroll);
        }}
        onRequestClose={() => {
          setIsModalOpen(false);
          setExpandedChat(null);
          setMessages([]);
          chatMessagesRef.current.removeEventListener('scroll', handleScroll);
        }}
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <h2>Chat Messages</h2>
        <div className={styles.dateHeaderDisplay}>{currentDate}</div>
        <div className={styles.chatMessages} ref={chatMessagesRef}>
          {Object.keys(groupedMessages).map((date) => (
            <div key={date}>
              <div className={styles.dateHeader} style={{ display: 'none' }} data-date={date}></div>
              {groupedMessages[date].map((message, index) => (
                <p key={index} className={styles.message}>
                  {formatTime(message.timestamp)} <strong>{username}:</strong> {message.message}
                </p>
              ))}
            </div>
          ))}
        </div>
        <div className={styles.modalFooter}>
          <button
            onClick={handleViewStats}
            className={styles.viewStatsButton}
          >
            View Channel
          </button>
          <button
            onClick={() => {
              setIsModalOpen(false);
              setExpandedChat(null);
              setMessages([]);
              chatMessagesRef.current.removeEventListener('scroll', handleScroll);
            }}
            className={styles.closeButton}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  ));
};

export default ChatLogs;
